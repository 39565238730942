export const ClaimTypeOptions = [
    { text: 'CMS-1500 (HCFA-1500 / Professional)', value: 'PROF_CLAIM' },
    { text: 'UB-04 (CMS-1450 / Institutional)', value: 'INST_CLAIM' }
];

export const PatientSexOptions = [
    { text: 'Male', value: 'M' },
    { text: 'Female', value: 'F' },
    { text: 'Unknown', value: 'U' }
];

export const PatientRelationshipOptions = [
    { text: 'Cadaver Donor', value: '40' },
    { text: 'Child', value: '19' },
    { text: 'Employee', value: '20' },
    { text: 'Life Partner', value: '53' },
    { text: 'Organ Donor', value: '39' },
    { text: 'Other Relationship', value: 'G8' },
    { text: 'Self', value: '18' },
    { text: 'Spouse', value: '01' },
    { text: 'Unknown', value: '21' }
];

export const PatientMaritalStatus = [
    { value: 'D', text: 'Divorced' },
    { value: 'M', text: 'Married' },
    { value: 'O', text: 'Other' },
    { value: 'S', text: 'Single' },
    { value: 'U', text: 'Unknown' },
    { value: 'W', text: 'Widow' }
];

export const PatientRaceLabels = [
    { value: '04', text: 'Asian' },
    { value: '02', text: 'Black or African American' },
    { value: '03', text: 'Native American or Alaskan Native' },
    { value: '05', text: 'Native Hawaiian or Other Pacific Islander' },
    { value: '88', text: 'Other Race' },
    { value: '99', text: 'Unknown' },
    { value: '01', text: 'White' }
];

export const PatientEmploymentStatus = [
    { value: 'E', text: 'Employed' },
    { value: 'F', text: 'Full-Time Student' },
    { value: 'P', text: 'Part-Time Student' }
];

export const PosTwoDigitCodes = [
    {
        value: '16',
        text:
            '16 - A short term accommodation such as a hotel, camp ground, hostel, cruise ship or resort where the patient receives care, and which is not identified by any other POS code.'
    },
    {
        value: '17',
        text:
            '17 - A walk-in health clinic, other than an office, urgent care facility, pharmacy or independent clinic and not described by any other Place of Service code, that is located within a retail operation and provides, on an ambulatory basis, preventive and primary care services'
    },
    { value: '42', text: '42 - Ambulance Air or Water' },
    { value: '41', text: '41 - Ambulance Land' },
    { value: '24', text: '24 - Ambulatory Surgical Center' },
    { value: '13', text: '13 - Assisted Living' },
    { value: '25', text: '25 - Birthing Center' },
    { value: '53', text: '53 - Community Mental Health' },
    { value: '61', text: '61 - Comprehensive Inpatient Rehab' },
    { value: '62', text: '62 - Comprehensive Outpatient Rehab' },
    { value: '33', text: '33 - Custodial Care' },
    { value: '23', text: '23 - Emergency Room Hospital' },
    { value: '65', text: '65 - End State Renal' },
    { value: '50', text: '50 - FQHC' },
    { value: '14', text: '14 - Group Home' },
    { value: '12', text: '12 - Home' },
    { value: '04', text: '04 - Homeless Shelter' },
    { value: '34', text: '34 - Hospice' },
    { value: '49', text: '49 - Independent Clinic' },
    { value: '81', text: '81 - Independent Lab' },
    { value: '05', text: '05 - Indian Health Service Freestanding' },
    { value: '06', text: '06 - Indian Health Service Provider Based' },
    { value: '21', text: '21 - Inpatient' },
    { value: '51', text: '51 - Inpatient Psychiatric' },
    { value: '54', text: '54 - Intermediate Care' },
    { value: '60', text: '60 - Mass Immunization Center' },
    { value: '26', text: '26 - Military Treatment' },
    { value: '15', text: '15 - Mobile Unit' },
    { value: '57', text: '57 - Non Residential Substance Abuse' },
    { value: '58', text: '58 - Non-residential Opioid Treatment Facility' },
    { value: '32', text: '32 - Nursing Facility' },
    { value: '19', text: '19 - Off-Campus Outpatient Hospital' },
    { value: '11', text: '11 - Office' },
    { value: '99', text: '99 - Other Place of Service' },
    { value: '22', text: '22 - Outpatient' },
    { value: '01', text: '01 - Pharmacy' },
    { value: '18', text: '18 - Place Of Employment/Worksite' },
    { value: '09', text: '09 - Prison/Correctional Facility' },
    { value: '52', text: '52 - Psychiatric Facility Partial Hospital' },
    { value: '56', text: '56 - Psychiatric Residential Treatment' },
    { value: '71', text: '71 - Public Health' },
    { value: '55', text: '55 - Residential Substance Abuse' },
    { value: '72', text: '72 - Rural Health' },
    { value: '03', text: '03 - School' },
    { value: '31', text: '31 - Skilled Nursing' },
    { value: '10', text: '10 - Telehealth Provided in Patient’s Home Eff 1-1-2022' },
    { value: '02', text: '02 - Telehealth Services Other than in Patient’s Home' },
    { value: '07', text: '07 - Tribal 638 Freestanding' },
    { value: '08', text: '08 - Tribal 638 Provider Based' },
    { value: '20', text: '20 - Urgent Care' }
];

export const ModifiersCodeList = [
    {
        value: 'FB',
        text:
            'FB - Ambulatory Surgical Center (ASC) Item provided without cost to provider, supplier or practitioner, or full credit received for replaced device.'
    },
    { value: 'EA', text: 'EA - Erythropoiesis Stimulating Agent (ESA) Anemia, chemo-induced' },
    { value: 'HY', text: 'HY - FUNDED BY JUVENILE JUSTICE AGENCY' },
    {
        value: 'AV',
        text: 'AV - Item furnished in conjunction with a prosthetic device, prosthetic or orthotic'
    },
    {
        value: 'AU',
        text: 'AU - Item furnished in conjunction with a urological, ostomy or tracheostomy supply'
    },
    { value: 'T4', text: 'T4 - Left foot, fifth digit' },
    { value: 'T3', text: 'T3 - Left foot, fourth digit.' },
    { value: 'TA', text: 'TA - Left foot, great toe.' },
    { value: 'T1', text: 'T1 - Left foot, second digit' },
    { value: 'T2', text: 'T2 - Left foot, third digit.' },
    { value: 'F4', text: 'F4 - Left hand, fifth digit.' },
    { value: 'F3', text: 'F3 - Left hand, fourth digit.' },
    { value: 'F1', text: 'F1 - Left hand, second digit' },
    { value: 'F2', text: 'F2 - Left hand, third digit' },
    { value: 'FA', text: 'FA - Left hand, thumb' },
    { value: 'E4', text: 'E4 - Lower right, eyelid' },
    {
        value: 'QK',
        text:
            'QK - Medical direction of two, three or four concurrent anesthesia procedures involving qualified individuals.'
    },
    {
        value: 'AD',
        text: 'AD - Medical supervision by a physician, more than four concurrent procedures'
    },
    {
        value: 'G8',
        text:
            'G8 - Monitored Anesthesia Care (MAC) for deep complex, complicated or markedly invasive surgical procedure'
    },
    { value: 'J1', text: 'J1 - No pay Competitive Acquisition Program (CAP)modifier for drug line' },
    { value: 'GX', text: 'GX - Notice of liability issued, voluntary under payer policy.' },
    {
        value: 'GJ',
        text: 'GJ - Opt-Out physician or practitioner service provided in an emergency or urgent service'
    },
    {
        value: 'QL',
        text:
            'QL - Patient pronounced dead after ambulance called (do not use origin and destination modifiers, only QL).'
    },
    {
        value: 'GG',
        text:
            'GG - Performance and payment of a screening mammography and diagnostic mammography on the same patient, same day'
    },
    {
        value: 'AS',
        text:
            'AS - Physician Assistant (PA), Clinical Nurse Specialist (CNS) or Nurse Practitioner (NP) services for assistant-at-surgery'
    },
    {
        value: '8P',
        text:
            '8P - Physician Quality Reporting System - Performance measure reporting modifier action not performed, reason not otherwise specified.'
    },
    {
        value: 'RA',
        text: 'RA - Replacement of a Durable Medical Equipment (DME), orthotic or prosthetic item.'
    },
    {
        value: 'RB',
        text:
            'RB - Replacement of a part of a Durable Medical Equipment (DME), orthotic or prosthetic item furnished as part of a repair'
    },
    { value: 'T9', text: 'T9 - Right foot, fifth digit.' },
    { value: 'T8', text: 'T8 - Right foot, fourth digit.' },
    { value: 'T5', text: 'T5 - Right foot, great toe.' },
    { value: 'T6', text: 'T6 - Right foot, second digit.' },
    { value: 'T7', text: 'T7 - Right foot, third digit' },
    { value: 'F9', text: 'F9 - Right hand, fifth digit.' },
    { value: 'F8', text: 'F8 - Right hand, fourth digit' },
    { value: 'F6', text: 'F6 - Right hand, second digit.' },
    { value: 'F7', text: 'F7 - Right hand, third digit.' },
    { value: 'F5', text: 'F5 - Right hand, thumb.' },
    {
        value: 'QJ',
        text:
            'QJ - Services/items provided to a prisoner or patient in state or local custody however, the state or local government, as applicable, meets the requirements in 42 CFR 411.4(b)., for outpatient claims, providers should append QJ on all lines with a line item date of service during the incarceration period. all associated charges should be billed as non covered'
    },
    {
        value: '25',
        text:
            '25 - Significant, separately identifiable Evaluation and Management (E/M) service by the same physician on the same day of the procedure or other service'
    },
    { value: 'GA', text: 'GA - Waiver of liability statement issued, as required by payer policy' },
    { value: 'CH', text: 'CH - 0 percent impaired, limited or restricted' },
    { value: 'CN', text: 'CN - 100 percent impaired, limited or restricted' },
    {
        value: 'XE',
        text: 'XE - A Service That Is Distinct Because It Occurred During A Separate Encounter'
    },
    {
        value: 'XP',
        text: 'XP - A Service That Is Distinct Because It Was Performed By A Different Practitioner'
    },
    {
        value: 'XS',
        text: 'XS - A Service That Is Distinct Because It Was Performed On A Separate Organ/Structure'
    },
    { value: 'AT', text: 'AT - Acute treatment' },
    { value: 'KA', text: 'KA - ADD ON OPTION/ACCESSORY FOR WHEELCHAIR' },
    {
        value: 'CP',
        text:
            'CP - Adjunctive service related to a procedure assigned to a comprehensive ambulatory payment classification (c-apc) procedure, but reported on a differ Code'
    },
    { value: 'JA', text: 'JA - Administered intravenously' },
    { value: 'J4', text: 'J4 - ADMINISTERED INTRAVENOUSLY' },
    { value: 'JB', text: 'JB - Administered subcutaneously' },
    { value: 'HB', text: 'HB - Adult Program - Non Geriatric' },
    { value: 'HC', text: 'HC - Adult program, geriatric' },
    { value: 'GZ', text: 'GZ - Advance Beneficiary Notice (ABN) was not signed by the beneficiary' },
    { value: 'AO', text: 'AO - Alternate payment method declined by provider' },
    { value: 'HH', text: 'HH - Ambulance - Hospital to Hospital' },
    { value: 'HG', text: 'HG - Ambulance - Hospital to Hospital based ESRD facility' },
    { value: 'HP', text: 'HP - Ambulance - Hospital to Physician’s office' },
    { value: 'HR', text: 'HR - Ambulance - Hospital to Residence' },
    {
        value: 'HE',
        text: 'HE - Ambulance - Hospital to Residential, domiciliary, custodial facility (other than 1819 facility)'
    },
    {
        value: 'HI',
        text:
            'HI - Ambulance - Hospital to Site of transfer (e.g. airport or helicopter pad) between modes of ambulance transport'
    },
    { value: 'HN', text: 'HN - Ambulance - Hospital to Skilled nursing facility' },
    { value: 'PR', text: "PR - Ambulance Service from a Physician's office to a Residence" },
    { value: 'QN', text: 'QN - Ambulance service furnished directly by a provider of services' },
    {
        value: 'QM',
        text: 'QM - Ambulance service provided under arrangement by a provider of services'
    },
    {
        value: '73',
        text: '73 - Ambulatory Surgical Center (ASC) Discontinued procedure prior to administration of anesthesia'
    },
    { value: 'SG', text: 'SG - Ambulatory Surgical Center (ASC) Facility service' },
    {
        value: 'FC',
        text: 'FC - Ambulatory Surgical Center (ASC) Partial credit received for replaced device'
    },
    {
        value: 'CE',
        text:
            'CE - AMCC test has been ordered by an ESRD facility or MCP physician that is a composite rate test but is beyond the normal frequency covered under the rate and is separately reimbursable based on medical necessity'
    },
    {
        value: 'CF',
        text:
            'CF - AMCC test has been ordered by an ESRD facility or MCP physician that is not part of the composite rate and is separately billable'
    },
    {
        value: 'CD',
        text:
            'CD - AMCC test has been ordered by an ESRD facility or MCP physician that is part of the composite rate and is not separately billable'
    },
    { value: '47', text: '47 - Anesthesia by surgeon' },
    {
        value: '23',
        text: '23 - Anesthesia is required for a service that usually does not require anesthesia'
    },
    { value: 'AA', text: 'AA - Anesthesia services performed personally by anesthesiologist' },
    { value: 'SQ', text: 'SQ - Antibiotic Therapy' },
    { value: 'V5', text: 'V5 - Any Vascular Catheter (alone or with any other vascular access)' },
    { value: 'VP', text: 'VP - Aphakic patient' },
    { value: 'V7', text: 'V7 - Arteriovenous Fistula Only (in use with two needles)' },
    {
        value: 'V6',
        text: 'V6 - Arteriovenous Graft (or other vascular access not including a vascular catheter)'
    },
    { value: '80', text: '80 - Assistant surgeon' },
    { value: '82', text: '82 - Assistant surgeon (when qualified resident surgeon not available)' },
    {
        value: 'CI',
        text: 'CI - At least 1 percent but less than 20 percent impaired, limited or restricted'
    },
    {
        value: 'CJ',
        text: 'CJ - At least 20 percent but less than 40 percent impaired, limited or restricted'
    },
    {
        value: 'CK',
        text: 'CK - At least 40 percent but less than 60 percent impaired, limited or restricted'
    },
    {
        value: 'CL',
        text: 'CL - At least 60 percent but less than 80 percent impaired, limited or restricted'
    },
    {
        value: 'CM',
        text: 'CM - At least 80 percent but less than 100 percent impaired, limited or restricted'
    },
    {
        value: 'GV',
        text: 'GV - Attending physician not employed or paid under arrangement by the patient s hospice provider'
    },
    { value: 'HN', text: 'HN - Bachelors degree level' },
    { value: 'TW', text: 'TW - Backup equipment' },
    { value: 'TQ', text: 'TQ - Basic life support transport by a volunteer' },
    {
        value: 'KB',
        text: 'KB - Beneficiary requested upgrade for abn, more than 4 modifiers identified on claim'
    },
    {
        value: 'KT',
        text:
            'KT - Beneficiary resides in a competitive bidding area and travels outside that competitive bidding area and receives a competitive bid item'
    },
    {
        value: 'KE',
        text:
            'KE - Bid under round one of the dmepos competitive bidding program for use with non-competitive bid base equipment'
    },
    { value: '50', text: '50 - Bilateral procedure' },
    { value: 'CR', text: 'CR - Catastrophe/Disaster-related claims' },
    {
        value: 'QX',
        text: 'QX - Certified Registered Nurse Anesthetist (CRNA) service with medical direction by a physician'
    },
    {
        value: 'QZ',
        text: 'QZ - Certified Registered Nurse Anesthetist (CRNA) service without medical direction by a physician'
    },
    { value: 'HA', text: 'HA - Child adolescent program' },
    { value: 'QQ', text: 'QQ - Claim submitted with a written statement of intent' },
    { value: 'QW', text: 'QW - Clinical Laboratory Improvement Amendments (CLIA) waived test' },
    { value: 'AH', text: 'AH - Clinical psychologist' },
    { value: 'AJ', text: 'AJ - Clinical social worker' },
    {
        value: 'PT',
        text: 'PT - Colorectal cancer screening test converted to diagnostic test or other procedure'
    },
    { value: 'J2', text: 'J2 - Competitive Acquisition Program (CAP) emergency re-supply' },
    { value: 'J3', text: 'J3 - Competitive Acquisition Program (CAP) Furnish as Written.' },
    { value: 'MS', text: 'MS - Competitive Acquisition Program (CAP) Medicare secondary payer' },
    { value: 'TG', text: 'TG - Complex/High tech level of care' },
    { value: 'JF', text: 'JF - Compounded Drug' },
    { value: 'FY', text: 'FY - Computed radiography services that are furnished for X-rays' },
    {
        value: 'CT',
        text:
            'CT - Computed tomography services furnished using equipment that does not meet each of the attributes of the National Electrical Manufacturers Association [NEMA] XR-29-2013 standard'
    },
    {
        value: 'C6',
        text: 'C6 - Consumer Directed Personal Assistant CDPA Basic – 15 Minutes Per 15 minutes'
    },
    {
        value: 'X1',
        text:
            'X1 - Continuous/Broad Services - For reporting services by clinicians who provide the principal care for a patient, with no planned endpoint of the relationship'
    },
    {
        value: 'X2',
        text:
            'X2 - Continuous/Focused services = For reporting services by clinicians whose expertise is needed for the ongoing management of a chronic disease or a condition that needs to be managed and followed'
    },
    { value: 'CS', text: 'CS - Cost Sharing for COVID-19 testing and visits related to testing.' },
    { value: 'FQ', text: 'FQ - counseling and therapy provided using audio-only telecommunications' },
    { value: 'H9', text: 'H9 - Court Ordered' },
    { value: 'FL', text: 'FL - Covid - Used for Appendix K flexibilities' },
    { value: 'CS', text: 'CS - Covid-19 testing related service' },
    { value: 'V1', text: 'V1 - Demonstration modifier 1' },
    { value: 'V2', text: 'V2 - Demonstration modifier 2' },
    { value: 'V3', text: 'V3 - Demonstration modifier 3' },
    { value: 'Q2', text: 'Q2 - Demonstration procedure/service' },
    { value: 'SE', text: 'SE - Designates that it is supplied by the state' },
    { value: 'AP', text: 'AP - DETERMINATION OF REFRACTIVE STATE WAS NOT PERFORMED' },
    {
        value: 'PD',
        text:
            'PD - Diagnostic or related nondiagnostic item or service provided in a wholly owned or operated entity to a patient who is admitted as an inpatient within 3 days'
    },
    { value: 'DH', text: 'DH - Diagnostic/therapeutic site other than P/H to a Hospital' },
    {
        value: 'DG',
        text: 'DG - Diagnostic/therapeutic site other than P/H to a Hospital-based Dialysis Facility'
    },
    {
        value: 'DJ',
        text: 'DJ - Diagnostic/therapeutic site other than P/H to a Non-Hospital-based Dialysis facility'
    },
    { value: 'DP', text: "DP - Diagnostic/therapeutic site other than P/H to a Physician's office" },
    { value: 'DR', text: 'DR - Diagnostic/therapeutic site other than P/H to a Residence' },
    {
        value: 'DE',
        text: 'DE - Diagnostic/therapeutic site other than P/H to a Residential/Domiciliary/Custodial fac.'
    },
    {
        value: 'DS',
        text: 'DS - Diagnostic/therapeutic site other than P/H to a Scene of Accident/Acute Event'
    },
    {
        value: 'DI',
        text: 'DI - Diagnostic/therapeutic site other than P/H to a Site of ambulance transport modes transfer'
    },
    {
        value: 'DN',
        text: 'DN - Diagnostic/therapeutic site other than P/H to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'DX',
        text: "DX - Diagnostic/therapeutic site other than P/H to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'DD',
        text: 'DD - Diagnostic/therapeutic site other than P/H to another Diagnostic/therapeutic site other than P/H'
    },
    { value: 'MO', text: 'MO - Difier Modifier Description' },
    { value: '74', text: '74 - Discontinued outpatient procedure after administration of anesthesia' },
    { value: '53', text: '53 - Discontinued procedure' },
    { value: '59', text: '59 - Distinct procedural service' },
    { value: 'LL', text: 'LL - DME Equipment Rental is to be applied against the purchase price' },
    { value: 'KL', text: 'KL - Dmepos item delivered via mail' },
    { value: 'KG', text: 'KG - Dmepos item subject to dmepos competitive bidding program number 1' },
    { value: 'KK', text: 'KK - Dmepos item subject to dmepos competitive bidding program number 2' },
    { value: 'KU', text: 'KU - Dmepos item subject to dmepos competitive bidding program number 3' },
    { value: 'KW', text: 'KW - Dmepos item subject to dmepos competitive bidding program number 4' },
    { value: 'KY', text: 'KY - Dmepos item subject to dmepos competitive bidding program number 5' },
    {
        value: 'KV',
        text:
            'KV - Dmepos item subject to dmepos competitive bidding program that is furnished as part of a professional service'
    },
    { value: 'KH', text: 'KH - Dmepos item, initial claim, purchase or first month rental' },
    { value: 'QW', text: 'QW - Clinical Laboratory Improvement Amendments (CLIA) waived test' },
    { value: 'AH', text: 'AH - Clinical psychologist' },
    { value: 'AJ', text: 'AJ - Clinical social worker' },
    {
        value: 'PT',
        text: 'PT - Colorectal cancer screening test converted to diagnostic test or other procedure'
    },
    { value: 'J2', text: 'J2 - Competitive Acquisition Program (CAP) emergency re-supply' },
    { value: 'J3', text: 'J3 - Competitive Acquisition Program (CAP) Furnish as Written.' },
    { value: 'MS', text: 'MS - Competitive Acquisition Program (CAP) Medicare secondary payer' },
    { value: 'TG', text: 'TG - Complex/High tech level of care' },
    { value: 'JF', text: 'JF - Compounded Drug' },
    { value: 'FY', text: 'FY - Computed radiography services that are furnished for X-rays' },
    {
        value: 'CT',
        text:
            'CT - Computed tomography services furnished using equipment that does not meet each of the attributes of the National Electrical Manufacturers Association [NEMA] XR-29-2013 standard'
    },
    {
        value: 'C6',
        text: 'C6 - Consumer Directed Personal Assistant CDPA Basic – 15 Minutes Per 15 minutes'
    },
    {
        value: 'X1',
        text:
            'X1 - Continuous/Broad Services - For reporting services by clinicians who provide the principal care for a patient, with no planned endpoint of the relationship'
    },
    {
        value: 'X2',
        text:
            'X2 - Continuous/Focused services = For reporting services by clinicians whose expertise is needed for the ongoing management of a chronic disease or a condition that needs to be managed and followed'
    },
    { value: 'CS', text: 'CS - Cost Sharing for COVID-19 testing and visits related to testing.' },
    { value: 'FQ', text: 'FQ - counseling and therapy provided using audio-only telecommunications' },
    { value: 'H9', text: 'H9 - Court Ordered' },
    { value: 'FL', text: 'FL - Covid - Used for Appendix K flexibilities' },
    { value: 'CS', text: 'CS - Covid-19 testing related service' },
    { value: 'V1', text: 'V1 - Demonstration modifier 1' },
    { value: 'V2', text: 'V2 - Demonstration modifier 2' },
    { value: 'V3', text: 'V3 - Demonstration modifier 3' },
    { value: 'Q2', text: 'Q2 - Demonstration procedure/service' },
    { value: 'SE', text: 'SE - Designates that it is supplied by the state' },
    { value: 'AP', text: 'AP - DETERMINATION OF REFRACTIVE STATE WAS NOT PERFORMED' },
    {
        value: 'PD',
        text:
            'PD - Diagnostic or related nondiagnostic item or service provided in a wholly owned or operated entity to a patient who is admitted as an inpatient within 3 days'
    },
    { value: 'DH', text: 'DH - Diagnostic/therapeutic site other than P/H to a Hospital' },
    {
        value: 'DG',
        text: 'DG - Diagnostic/therapeutic site other than P/H to a Hospital-based Dialysis Facility'
    },
    {
        value: 'DJ',
        text: 'DJ - Diagnostic/therapeutic site other than P/H to a Non-Hospital-based Dialysis facility'
    },
    { value: 'DP', text: "DP - Diagnostic/therapeutic site other than P/H to a Physician's office" },
    { value: 'DR', text: 'DR - Diagnostic/therapeutic site other than P/H to a Residence' },
    {
        value: 'DE',
        text: 'DE - Diagnostic/therapeutic site other than P/H to a Residential/Domiciliary/Custodial fac.'
    },
    {
        value: 'DS',
        text: 'DS - Diagnostic/therapeutic site other than P/H to a Scene of Accident/Acute Event'
    },
    {
        value: 'DI',
        text: 'DI - Diagnostic/therapeutic site other than P/H to a Site of ambulance transport modes transfer'
    },
    {
        value: 'DN',
        text: 'DN - Diagnostic/therapeutic site other than P/H to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'DX',
        text: "DX - Diagnostic/therapeutic site other than P/H to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'DD',
        text: 'DD - Diagnostic/therapeutic site other than P/H to another Diagnostic/therapeutic site other than P/H'
    },
    { value: 'MO', text: 'MO - Difier Modifier Description' },
    { value: '74', text: '74 - Discontinued outpatient procedure after administration of anesthesia' },
    { value: '53', text: '53 - Discontinued procedure' },
    { value: '59', text: '59 - Distinct procedural service' },
    { value: 'LL', text: 'LL - DME Equipment Rental is to be applied against the purchase price' },
    { value: 'KL', text: 'KL - Dmepos item delivered via mail' },
    { value: 'KG', text: 'KG - Dmepos item subject to dmepos competitive bidding program number 1' },
    { value: 'KK', text: 'KK - Dmepos item subject to dmepos competitive bidding program number 2' },
    { value: 'KU', text: 'KU - Dmepos item subject to dmepos competitive bidding program number 3' },
    { value: 'KW', text: 'KW - Dmepos item subject to dmepos competitive bidding program number 4' },
    { value: 'KY', text: 'KY - Dmepos item subject to dmepos competitive bidding program number 5' },
    {
        value: 'KV',
        text:
            'KV - Dmepos item subject to dmepos competitive bidding program that is furnished as part of a professional service'
    },
    { value: 'KH', text: 'KH - Dmepos item, initial claim, purchase or first month rental' },
    {
        value: 'KJ',
        text: 'KJ - Dmepos item, parenteral enteral nutrition (pen) pump or capped rental, months four to fifteen'
    },
    { value: 'KI', text: 'KI - Dmepos item, second or third month rental' },
    { value: 'HP', text: 'HP - Doctoral Level' },
    {
        value: 'QP',
        text: 'QP - Documentation is on file showing that the lab test was ordered individually or as panel'
    },
    {
        value: 'GS',
        text: 'GS - Dosage of EPO or DPO has been reduced and maintained in response to hematocrit or hemoglobin level'
    },
    { value: 'A8', text: 'A8 - Dressing for eight wounds' },
    { value: 'A5', text: 'A5 - Dressing for five wounds' },
    { value: 'A4', text: 'A4 - Dressing for four wounds' },
    { value: 'A9', text: 'A9 - Dressing for nine or more wounds' },
    { value: 'A1', text: 'A1 - Dressing for one wound' },
    { value: 'A7', text: 'A7 - Dressing for seven wounds' },
    { value: 'A6', text: 'A6 - Dressing for six wounds' },
    { value: 'A3', text: 'A3 - Dressing for three wounds' },
    { value: 'A2', text: 'A2 - Dressing for two wounds' },
    { value: 'JW', text: 'JW - Drug amount discarded/not administered to any patient' },
    {
        value: 'TB',
        text:
            'TB - Drug or biological acquired with 340B drug pricing program discount, reported for informational purposes'
    },
    { value: 'KD', text: 'KD - Drug or biological infused through Durable Medical Equipment (DME)' },
    { value: 'RD', text: 'RD - DRUG PROVIDED TO BENEFICIARY, BUT NOT ADMINISTERED' },
    { value: 'TL', text: 'TL - Early Intervention/Individualized Family Service Plan (IFSP)' },
    {
        value: 'MA',
        text:
            'MA - Eff 1-1-2020: Ordering professional is not required to consult a clinical decision support mechanism due to service being rendered to a patient with a suspected or confirmed emergency medical condition'
    },
    {
        value: 'MC',
        text:
            'MC - Eff 1-1-2020: Ordering professional is not required to consult a clinical decision support mechanism due to the significant hardship exception of electronic health record or clinical decision support mechanism vendor issues'
    },
    {
        value: 'MD',
        text:
            'MD - Eff 1-1-2020: Ordering professional is not required to consult a clinical decision support mechanism due to the significant hardship exception of extreme and uncontrollable circumstances'
    },
    {
        value: 'MB',
        text:
            'MB - Eff 1-1-2020: Ordering professional is not required to consult a clinical decision support mechanism due to the significant hardship exception of insufficient internet access'
    },
    {
        value: 'ME',
        text:
            'ME - Eff 1-1-2020: The order for this service adheres to appropriate use criteria in the clinical decision support mechanism consulted by the ordering professional'
    },
    {
        value: 'MF',
        text:
            'MF - Eff 1-1-2020: The order for this service does not adhere to the appropriate use criteria in the clinical decision support mechanism consulted by the ordering professional'
    },
    {
        value: 'MG',
        text:
            'MG - Eff 1-1-2020: The order for this service does not have applicable appropriate use criteria in the qualified clinical decision support mechanism consulted by the ordering professional'
    },
    {
        value: 'MH',
        text:
            'MH - Eff 1-1-2020: Unknown if ordering professional consulted a clinical decision support mechanism for this service, related information was not provided to the furnishing professional or provider'
    },
    { value: 'EM', text: 'EM - Emergency reserve supply (for ESRD benefit only)' },
    { value: 'ET', text: 'ET - Emergency services' },
    {
        value: 'ET',
        text: 'ET - Emergency treatment (dental procedures performed in emergency situations'
    },
    {
        value: 'X3',
        text:
            'X3 - Episodic/Broad services = For reporting services by clinicians who have broad responsibility for the comprehensive needs of the patients, that is limited to a defined period and circumstance, such as a hospitalization.'
    },
    {
        value: 'X4',
        text:
            'X4 - Episodic/Focused services = For reporting services by specialty focused clinicians who provide time-limited care. The patient has a problem, acute or chronic, that will be treated with surgery, radiation, or some other type of generally time-limited intervention'
    },
    {
        value: 'EB',
        text: 'EB - Erythropoiesis Stimulating Agent (ESA) administered to treat anemia due to anti-cancer radiotherapy'
    },
    {
        value: 'EC',
        text:
            'EC - Erythropoiesis Stimulating Agent (ESA) administered to treat anemia not due to anti-cancer chemotherapy or anti-cancer radiotherapy'
    },
    {
        value: 'G6',
        text: 'G6 - ESRD patient for whom less than seven dialysis sessions have been provided in a month'
    },
    { value: 'NY', text: 'NY - ESRD Personal Care' },
    { value: '57', text: '57 - Evaluation and Management (E/M) Decision for surgery' },
    {
        value: '24',
        text:
            '24 - Evaluation and Management (E/M) Unrelated E/M service by the same physician during a postoperative period'
    },
    { value: 'TK', text: 'TK - Extra patient or passenger, non-ambulance' },
    { value: 'HR', text: 'HR - Family/Couple With Client Present' },
    { value: 'QA', text: 'QA - Fda investigational device exemption' },
    { value: 'LS', text: 'LS - FDA-monitored Intraocular Lens (IOL) implant' },
    { value: 'KP', text: 'KP - First drug of a multiple drug unit dose formulation' },
    { value: 'TS', text: 'TS - Follow-up service' },
    { value: 'HU', text: 'HU - FUNDED BY CHILD WELFARE AGENCY HCPCS Modifier Code Code' },
    { value: 'HZ', text: 'HZ - FUNDED BY CRIMINAL JUSTICE AGENCY' },
    { value: 'HW', text: 'HW - Funded by State Mental Health Agency' },
    { value: 'HV', text: 'HV - Funded state addictions agency' },
    {
        value: 'RE',
        text: 'RE - Furnished in full compliance with fda-mandated risk evaluation and mitigation strategy (rems)'
    },
    {
        value: 'KS',
        text: 'KS - Glucose monitor supply for diabetic beneficiary not treated with insulin'
    },
    { value: 'HQ', text: 'HQ - GROUP SETTING' },
    { value: '96', text: '96 - Habilitative Services' },
    { value: 'SZ', text: 'SZ - Habilitative Services' },
    {
        value: 'ED',
        text:
            'ED - Hematocrit level has exceeded 39 percent (or hemoglobin level has exceeded 13.0 g/dL) for three or more consecutive billing cycles immediately prior to and including the current cycle'
    },
    {
        value: 'EE',
        text:
            'EE - Hematocrit level has exceeded 39 percent (or hemoglobin level has not exceeded 13.0 g/dL) for less than three consecutive billing cycles immediately prior to and including the current cycle'
    },
    { value: '32', text: '32 - HIGH TECH HIT RN' },
    { value: 'SS', text: 'SS - HOME INFUSION SERVICES PROVIDED IN THE INFUSION' },
    { value: 'HD', text: 'HD - Hospital to a Diagnostic/therapeutic site other than P/H' },
    { value: 'HJ', text: 'HJ - Hospital to a Non-Hospital-based Dialysis facility' },
    { value: 'HS', text: 'HS - Hospital to a Scene of Accident/Acute Event' },
    { value: 'HX', text: "HX - Hospital to an Intermediate Stop at Physician's Office" },
    { value: 'GH', text: 'GH - Hospital-based Dialysis Facility to a Hospital' },
    { value: 'GR', text: 'GR - Hospital-based Dialysis Facility to a Residence' },
    { value: 'TM', text: 'TM - Individualized Education Program (IEP)' },
    { value: 'TT', text: 'TT - Individualized service provided to more than one' },
    { value: 'V8', text: 'V8 - Infection present' },
    {
        value: 'HI',
        text: 'HI - Integrated mental health and mental retardation/developmental disabilities program'
    },
    { value: 'HH', text: 'HH - Integrated Mental Health/Substance Abuse Program' },
    { value: 'TF', text: 'TF - Intermediate level of care' },
    { value: 'HL', text: 'HL - Intern' },
    {
        value: 'Q0',
        text:
            'Q0 - Investigational clinical service provided in a clinical research study that is in an approved clinical research study'
    },
    { value: 'KF', text: 'KF - Item designated by fda as class iii devices' },
    { value: 'AW', text: 'AW - Item furnished in conjunction with a surgical dressing' },
    { value: 'AX', text: 'AX - Item furnished in conjunction with dialysis services' },
    {
        value: 'BA',
        text: 'BA - Item furnished in conjunction with parenteral enteral nutrition (pen) services'
    },
    {
        value: 'AY',
        text:
            'AY - Item or service furnished to an End Stage Renal Disease (ESRD) patient that is not for the treatment of ESRD'
    },
    {
        value: 'QV',
        text: 'QV - Item or service provided as routine care in a medicare qualifying clinical trail'
    },
    {
        value: 'GY',
        text: 'GY - Item or service statutorily excluded or does not meet the definition of any Medicare benefit'
    },
    { value: 'QR', text: 'QR - Item/service in medicare study - oxygen' },
    { value: 'LR', text: 'LR - Laboratory round trip' },
    { value: 'LD', text: 'LD - Left anterior descending coronary artery' },
    { value: 'LC', text: 'LC - Left circumflex coronary artery' },
    { value: 'LM', text: 'LM - Left Main' },
    { value: 'LT', text: 'LT - Left side' },
    { value: 'HM', text: 'HM - Less than Bachelor’s degree level' },
    { value: 'U9', text: 'U9 - Level of care' },
    { value: 'LN', text: 'LN - Licensed Practioner' },
    { value: 'Q3', text: 'Q3 - Live kidney donor surgery and related services' },
    { value: 'K0', text: 'K0 - Lower extremity prosthesis functional level 0' },
    { value: 'K1', text: 'K1 - Lower extremity prosthesis functional level 1' },
    { value: 'K2', text: 'K2 - Lower extremity prosthesis functional level 2' },
    { value: 'K3', text: 'K3 - Lower extremity prosthesis functional level 3' },
    { value: 'E2', text: 'E2 - Lower left eyelid' },
    {
        value: 'K4',
        text:
            'K4 - Lower prosthesis functional level 4 has the ability or potential for prosthetic ambulation that exceeds the basic ambulation skills, exhibiting high impact, stress, or energy levels, typical of the prosthetic demands of the child, active adult, or athlete'
    },
    { value: 'TE', text: 'TE - LPN/LVN' },
    { value: 'HO', text: 'HO - Masters degree level' },
    { value: 'U1', text: 'U1 - Medicaid Level of care 1' },
    { value: 'UA', text: 'UA - Medicaid level of care 10' },
    { value: 'UB', text: 'UB - Medicaid level of care 11' },
    { value: 'UC', text: 'UC - Medicaid level of care 12' },
    { value: 'UD', text: 'UD - Medicaid level of care 13' },
    { value: 'U2', text: 'U2 - Medicaid level of care 2' },
    { value: 'U3', text: 'U3 - Medicaid level of care 3' },
    { value: 'U4', text: 'U4 - Medicaid Level Of Care 4, As Defined By Each State' },
    { value: 'U6', text: 'U6 - Medicaid level of care 6' },
    { value: 'U7', text: 'U7 - Medicaid level of care 7' },
    { value: 'U8', text: 'U8 - Medicaid level of care 8' },
    { value: 'U8', text: 'U8 - Medicaid level of care 8, as defined by each state' },
    { value: 'U9', text: 'U9 - Medicaid level of care 9' },
    { value: 'QY', text: 'QY - Medical direction of one CRNA by an anesthesiologist' },
    { value: 'TP', text: 'TP - Medical transport, unloaded' },
    {
        value: 'GL',
        text:
            'GL - Medically unnecessary upgrade provided instead of non-upgraded item, no charge, no advance beneficiary notice (abn)'
    },
    { value: 'PN', text: 'PN - Medicare Advantage Only off campus provider based locations' },
    { value: 'VM', text: 'VM - Medicare diabetes prevention program (mdpp) virtual make-up session' },
    { value: 'M2', text: 'M2 - Medicare Secondary Payer - MSP' },
    { value: 'SK', text: 'SK - Member of high risk population' },
    { value: 'ZC', text: 'ZC - Merck/samsung bioepis' },
    { value: '81', text: '81 - Minimum assistant surgeon' },
    {
        value: 'G9',
        text: 'G9 - Monitored Anesthesia Care (MAC) for patient who has history of severe cardiopulmonary condition'
    },
    { value: 'QS', text: 'QS - Monitored Anesthesia Care (MAC) services' },
    { value: 'G2', text: 'G2 - Most recent URR reading of 60-64.9 percent' },
    { value: 'G3', text: 'G3 - Most recent URR reading of 65-69.9 percent' },
    { value: 'G4', text: 'G4 - Most recent URR reading of 70-74.9 percent' },
    { value: 'G5', text: 'G5 - Most recent URR reading of 75 percent or greater' },
    { value: 'G1', text: 'G1 - Most recent URR reading of less than 60 percent' },
    { value: 'HT', text: 'HT - MULTI-DISCIPLINARY TEAM' },
    { value: '99', text: '99 - Multiple modifiers' },
    {
        value: '27',
        text: '27 - Multiple outpatient hospital Evaluation and Management (E/M) encounters on the same date'
    },
    { value: 'GM', text: 'GM - Multiple patients on one ambulance trip' },
    { value: '51', text: '51 - Multiple procedures' },
    { value: 'NB', text: 'NB - NEBULIZER SYSTEM, ANY TYPE, FDA-CLEARED' },
    { value: 'KZ', text: 'KZ - NEW COVERAGE NOT IMPLEMENTED BY MANAGED CARE' },
    { value: 'NU', text: 'NU - New durable medical equipment purchase' },
    { value: 'V9', text: 'V9 - No infection present' },
    {
        value: 'EY',
        text: 'EY - No physician or other licensed health care provider order on file for this item or service'
    },
    {
        value: 'JD',
        text: 'JD - Non-Hospital-based Dialysis facility to a Diagnostic/therapeutic site other than P/H'
    },
    { value: 'JH', text: 'JH - Non-Hospital-based Dialysis facility to a Hospital' },
    {
        value: 'JG',
        text: 'JG - Non-Hospital-based Dialysis facility to a Hospital-based Dialysis Facility'
    },
    { value: 'JP', text: "JP - Non-Hospital-based Dialysis facility to a Physician's office" },
    { value: 'JR', text: 'JR - Non-Hospital-based Dialysis facility to a Residence' },
    {
        value: 'JE',
        text: 'JE - Non-Hospital-based Dialysis facility to a Residential/Domiciliary/Custodial fac.'
    },
    {
        value: 'JS',
        text: 'JS - Non-Hospital-based Dialysis facility to a Scene of Accident/Acute Event'
    },
    {
        value: 'JI',
        text: 'JI - Non-Hospital-based Dialysis facility to a Site of ambulance transport modes transfer'
    },
    {
        value: 'JN',
        text: 'JN - Non-Hospital-based Dialysis facility to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'JX',
        text: "JX - Non-Hospital-based Dialysis facility to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'JJ',
        text: 'JJ - Non-Hospital-based Dialysis facility to another Non-Hospital-based Dialysis facility'
    },
    { value: 'AK', text: 'AK - Non-participating physician' },
    { value: 'GF', text: 'GF - Non-physician services in a Critical Access Hospital (CAH)' },
    { value: 'ZA', text: 'ZA - Novartis/sandoz' },
    { value: 'SB', text: 'SB - Nurse midwife' },
    { value: 'RN', text: 'RN - Nursing' },
    { value: 'TH', text: 'TH - Obstetrical treatment/services, prenatal or postpartum' },
    { value: 'EO', text: 'EO - Occupational Therapy' },
    { value: 'Q7', text: 'Q7 - One class A finding' },
    { value: 'Q9', text: 'Q9 - One class B and two class C findings' },
    {
        value: 'X5',
        text:
            'X5 - Only as Ordered by Another Clinician = For reporting services by a clinician who furnishes care to the patient only as ordered by another clinician. This patient relationship category is reported for patient relationships that may not be adequately captured in the X1, X2, X3, X4 modifier categories'
    },
    { value: 'HG', text: 'HG - OPIOID ADDICTION TREATMENT PROGRAM' },
    {
        value: 'DA',
        text: 'DA - Oral health assessment by a licensed health professional other than a dentist'
    },
    { value: 'BO', text: 'BO - Orally administered nutrition, not by feeding tube' },
    { value: 'HE', text: 'HE - Other Mid-level Mental Health Professional' },
    {
        value: 'CO',
        text: 'CO - Outpatient occupational therapy service provided by occupational therapy assistance'
    },
    {
        value: 'CQ',
        text: 'CQ - Outpatient physical therapy service provided by physical therapy assistant'
    },
    { value: 'QH', text: 'QH - Oxygen conserving device is being used with an oxygen delivery system' },
    {
        value: 'SA',
        text: 'SA - PA, ANP, or CRNFA is assisting with any other procedure that DOES NOT include surgery'
    },
    {
        value: 'SY',
        text:
            'SY - Persons who are in close contact with member of high-risk population (use only with codes for immunization)'
    },
    { value: 'ZB', text: 'ZB - PFIZER/HOSPIRA' },
    { value: 'SV', text: "SV - Pharmaceuticals delivered to patient's home but not utilized" },
    {
        value: 'P6',
        text: 'P6 - Physical Status A declared brain-dead patient whose organs are being removed for donor purposes'
    },
    {
        value: 'P5',
        text: 'P5 - Physical Status A moribund patient who is not expected to survive without the operation'
    },
    { value: 'P1', text: 'P1 - Physical Status A normal healthy patient' },
    { value: 'P2', text: 'P2 - Physical Status A patient with mild systemic disease' },
    { value: 'P3', text: 'P3 - Physical Status A patient with severe systemic disease' },
    {
        value: 'P4',
        text: 'P4 - Physical Status A patient with severe systemic disease that is a constant threat to life'
    },
    {
        value: 'GO',
        text:
            'GO - Physical/Occupational Therapy (PT/OT) Services delivered under an outpatient occupational therapy plan of care'
    },
    {
        value: 'GP',
        text:
            'GP - Physical/Occupational Therapy (PT/OT) Services delivered under an outpatient physical therapy plan of care'
    },
    {
        value: 'GN',
        text:
            'GN - Physical/Occupational Therapy (PT/OT) Services delivered under an outpatient speech language pathology plan of care'
    },
    { value: 'AM', text: 'AM - Physician Assistant (PA) Services' },
    { value: 'AR', text: 'AR - Physician provided service in a Physician Scarcity Area (PSA)' },
    {
        value: 'AZ',
        text:
            'AZ - Physician providing a service in a dental health professional shortage area for the purpose of an Electronic Health Record (EHR) incentive payment'
    },
    {
        value: 'AQ',
        text: 'AQ - Physician providing a service in a Health Professional Shortage Area (HPSA)'
    },
    { value: 'QB', text: 'QB - Physician providing service in a rural HPSA' },
    { value: 'QU', text: 'QU - Physician providing service in an urban Health Professional Shortage' },
    {
        value: '1P',
        text: '1P - Physician Quality Reporting System - Performance measure exclusion modifier due to medical reasons'
    },
    {
        value: '2P',
        text: '2P - Physician Quality Reporting System - Performance measure exclusion modifier due to patient reasons'
    },
    {
        value: '3P',
        text: '3P - Physician Quality Reporting System - Performance measure exclusion modifier due to system reasons'
    },
    { value: 'PH', text: "PH - Physician's office to a Hospital" },
    { value: 'PE', text: "PE - Physician's office to a Residential/Domiciliary/Custodial facility" },
    { value: 'CG', text: 'CG - Policy criteria applied' },
    { value: 'UR', text: 'UR - Portable X-Ray Supplier Five patients served' },
    { value: 'UQ', text: 'UQ - Portable X-Ray Supplier Four patients served' },
    { value: 'US', text: 'US - Portable X-Ray Supplier Six patients served' },
    { value: 'UP', text: 'UP - Portable X-Ray Supplier Three patients served' },
    { value: 'UN', text: 'UN - Portable X-Ray Supplier Two patients served' },
    {
        value: 'PI',
        text:
            'PI - Positron Emission Tomography (PET) or PET/Computed Tomography (CT) to inform the initial treatment strategy of tumors that are biopsy-proven or strongly suspected of being cancerous based on other diagnostic testing'
    },
    {
        value: 'PS',
        text:
            'PS - Positron Emission Tomography (PET) or PET/Computed Tomography (CT) to inform the subsequent treatment strategy of cancerous tumors when the beneficiary s treating physician determines that the PET study is needed to inform subsequent anti-tumor strategy'
    },
    { value: 'PM', text: 'PM - Post mortem' },
    { value: '55', text: '55 - Postoperative management only' },
    {
        value: 'G7',
        text: 'G7 - Pregnancy resulted from rape or incest or pregnancy certified by physician as life threatening'
    },
    { value: '56', text: '56 - Preoperative management only' },
    {
        value: 'QF',
        text: 'QF - Prescribed amount of oxygen exceeds 4 lpm and portable oxygen is prescribed'
    },
    { value: 'QG', text: 'QG - Prescribed amount of oxygen is greater than 4 liters per minute (lpm)' },
    { value: 'QE', text: 'QE - Prescribed amount of oxygen is less than 1 liter per minute (lpm)' },
    { value: '33', text: '33 - Preventive Services' },
    {
        value: 'AG',
        text:
            "AG - Primary surgeon when there's more than one surgeon providing a service on the same patient. If more than one primary surgeon performing separate services on the same date of service, in different specialty areas."
    },
    { value: 'AI', text: 'AI - Principal physician of record' },
    { value: 'CC', text: 'CC - Procedure code change' },
    {
        value: 'CA',
        text:
            'CA - Procedure payable only in the inpatient setting when performed emergently on an outpatient who expires prior to admission'
    },
    {
        value: 'SU',
        text: "SU - Procedure performed in physician's office (to denote use of facility and equipment)"
    },
    { value: '63', text: '63 - Procedure Performed on Infants less than 4kg' },
    { value: '26', text: '26 - Professional component' },
    { value: 'TJ', text: 'TJ - Program group, child and/or adolescent' },
    { value: 'PL', text: 'PL - PROGRESSIVE ADDITION LENSES' },
    { value: '21', text: '21 - Prolonged E & M Services' },
    {
        value: 'LI',
        text: 'LI - Provider attestation that the hospital laboratory test(s) is not packaged under the hospital OPPS'
    },
    { value: 'RI', text: 'RI - Ramus Intermedius' },
    {
        value: 'GK',
        text: 'GK - Reasonable and necessary item/service associated with a ga or gz modifier'
    },
    { value: 'QD', text: 'QD - Recording and storage in solid state memory by digital recorder' },
    { value: 'QT', text: 'QT - Recording and Storage on a tape' },
    { value: '52', text: '52 - Reduced services' },
    { value: '90', text: '90 - Referenced (outside) laboratory' },
    { value: 'AE', text: 'AE - Registered dietitian' },
    { value: '97', text: '97 - Rehabilitative Services' },
    { value: 'ST', text: 'ST - Related to trauma or injury' },
    { value: 'RR', text: 'RR - Rental' },
    { value: 'KR', text: 'KR - Rental item, billing for partial month' },
    { value: '91', text: '91 - Repeat clinical diagnostic laboratory test' },
    { value: '77', text: '77 - Repeat procedure by another physician' },
    { value: '76', text: '76 - Repeat procedure by same physician' },
    { value: 'RP', text: 'RP - Replacement and repair' },
    { value: 'KM', text: 'KM - Replacement of facial prosthesis including new impression/moulage' },
    { value: 'KN', text: 'KN - Replacement of facial prosthesis using previous master model' },
    { value: 'KC', text: 'KC - Replacement of special power wheelchair interface' },
    { value: 'KX', text: 'KX - Requirements specified in the medical policy have been met' },
    { value: 'RH', text: 'RH - Residence to a Hospital' },
    { value: 'RJ', text: 'RJ - Residence to Freestanding ESRD facility' },
    { value: 'EH', text: 'EH - Residential/Domiciliary/Custodial fac. to a Hospital' },
    {
        value: 'EG',
        text: 'EG - Residential/Domiciliary/Custodial fac. to a Hospital-based Dialysis Facility'
    },
    { value: 'ER', text: 'ER - Residential/Domiciliary/Custodial fac. to a Residence' },
    {
        value: 'ES',
        text: 'ES - Residential/Domiciliary/Custodial fac. to a Scene of Accident/Acute Event'
    },
    {
        value: 'EI',
        text: 'EI - Residential/Domiciliary/Custodial fac. to a Site of ambulance transport modes transfer'
    },
    {
        value: 'EN',
        text: 'EN - Residential/Domiciliary/Custodial fac. to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'EX',
        text: "EX - Residential/Domiciliary/Custodial fac. to an Intermediate Stop at Physician's Office"
    },
    { value: 'RC', text: 'RC - Right coronary artery' },
    { value: 'RT', text: 'RT - Right side' },
    { value: 'TD', text: 'TD - RN' },
    {
        value: 'Q1',
        text:
            'Q1 - Routine clinical service provided in a clinical research study that is in an approved clinical research study'
    },
    { value: 'TN', text: 'TN - Rural/Outside providers customary service area' },
    { value: 'TR', text: 'TR - School-based Individual Education Program (IEP) services provided' },
    { value: 'SH', text: 'SH - Second concurrently administered infusion therapy' },
    { value: 'SF', text: 'SF - Second opinion ordered by a Professional Review Organization' },
    { value: 'KQ', text: 'KQ - Second or subsequent drug of a multiple drug unit dose formulation' },
    { value: 'SM', text: 'SM - Second surgical opinion' },
    { value: 'L1', text: 'L1 - Separately Payable Lab Test' },
    {
        value: 'Q4',
        text: 'Q4 - Service for ordering/referring physician qualifies as a service exemption'
    },
    {
        value: 'Q5',
        text: 'Q5 - Service furnished by a substitute physician under a reciprocal billing arrangement'
    },
    { value: 'GW', text: 'GW - Service not related to the hospice patient s terminal condition' },
    {
        value: 'CB',
        text:
            'CB - Service ordered by a RDF physician as part End Stage Renal Disease (ESRD) beneficiary s dialysis benefit'
    },
    {
        value: 'GE',
        text:
            'GE - Service performed by a resident without the presence of a teaching physician under the primary care exception'
    },
    {
        value: 'GC',
        text: 'GC - Service performed in part by a resident under the direction of a teaching physician'
    },
    {
        value: 'EP',
        text:
            'EP - Service provided as part of Medicaid early periodic screening diagnosis and treatment (EPSDT) program'
    },
    { value: 'FP', text: 'FP - Service Provided as part of medicaid family planning program' },
    { value: 'Q6', text: 'Q6 - Services furnished by a locum tenens physician' },
    { value: 'UJ', text: 'UJ - Services provided at night 7pm - 7am by medical transportation' },
    { value: 'SW', text: 'SW - SERVICES PROVIDED BY A CERTIFIED DIABETIC' },
    {
        value: 'SD',
        text: 'SD - Services provided by registered nurse with specialized, highly technical home infusion'
    },
    { value: 'UG', text: 'UG - Services provided in the afternoon' },
    { value: 'UH', text: 'UH - Services provided in the evening' },
    { value: 'UF', text: 'UF - Services provided in the morning' },
    {
        value: 'UK',
        text: 'UK - Services provided on behalf of beneficiary to someone other than the beneficiary'
    },
    {
        value: 'PO',
        text:
            'PO - Services, procedures, and/or surgeries furnished at off-campus provider-based outpatient departments'
    },
    { value: 'SC', text: 'SC - Single Chamber and Dual Chamber Permanent Cardiac Pacemakers' },
    { value: 'QC', text: 'QC - Single channel monitoring' },
    { value: 'KO', text: 'KO - Single drug unit dose formulation' },
    {
        value: 'ID',
        text: 'ID - Site of ambulance transport modes transfer to a Diagnostic/therapeutic site other than P/H'
    },
    { value: 'IH', text: 'IH - Site of ambulance transport modes transfer to a Hospital' },
    {
        value: 'IG',
        text: 'IG - Site of ambulance transport modes transfer to a Hospital-based Dialysis Facility'
    },
    {
        value: 'IJ',
        text: 'IJ - Site of ambulance transport modes transfer to a Non-Hospital-based Dialysis facility'
    },
    { value: 'IP', text: "IP - Site of ambulance transport modes transfer to a Physician's office" },
    { value: 'IR', text: 'IR - Site of ambulance transport modes transfer to a Residence' },
    {
        value: 'IE',
        text: 'IE - Site of ambulance transport modes transfer to a Residential/Domiciliary/Custodial fac.'
    },
    {
        value: 'IS',
        text: 'IS - Site of ambulance transport modes transfer to a Scene of Accident/Acute Event'
    },
    {
        value: 'IN',
        text: 'IN - Site of ambulance transport modes transfer to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'IX',
        text: "IX - Site of ambulance transport modes transfer to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'II',
        text: 'II - Site of ambulance transport modes transfer to another Site of ambulance transport modes transfer'
    },
    {
        value: 'ND',
        text: 'ND - Skilled Nursing Facility (SNF) to a Diagnostic/therapeutic site other than P/H'
    },
    { value: 'NH', text: 'NH - Skilled Nursing Facility (SNF) to a Hospital' },
    { value: 'NG', text: 'NG - Skilled Nursing Facility (SNF) to a Hospital-based Dialysis Facility' },
    {
        value: 'NJ',
        text: 'NJ - Skilled Nursing Facility (SNF) to a Non-Hospital-based Dialysis facility'
    },
    { value: 'NP', text: 'NP - Skilled Nursing Facility (SNF) to a Physicians office' },
    { value: 'NR', text: 'NR - Skilled Nursing Facility (SNF) to a Residence' },
    {
        value: 'NE',
        text: 'NE - Skilled Nursing Facility (SNF) to a Residential/Domiciliary/Custodial fac.'
    },
    { value: 'NS', text: 'NS - Skilled Nursing Facility (SNF) to a Scene of Accident/Acute Event' },
    {
        value: 'NI',
        text: 'NI - Skilled Nursing Facility (SNF) to a Site of ambulance transport modes transfer'
    },
    {
        value: 'NX',
        text: "NX - Skilled Nursing Facility (SNF) to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'NN',
        text: 'NN - Skilled Nursing Facility (SNF) to another Skilled Nursing Facility (SNF)'
    },
    { value: 'JC', text: 'JC - Skin substitute used as a graft' },
    { value: 'BL', text: 'BL - Special acquisition of blood and blood products' },
    { value: 'TU', text: 'TU - Special payment rate, overtime' },
    { value: 'TV', text: 'TV - Special payment rates, holidays/weekends' },
    { value: 'HK', text: 'HK - Specialized mental health programs for high-risk populations' },
    { value: 'QG', text: 'QG - Prescribed amount of oxygen is greater than 4 liters per minute (lpm)' },
    { value: 'QE', text: 'QE - Prescribed amount of oxygen is less than 1 liter per minute (lpm)' },
    { value: '33', text: '33 - Preventive Services' },
    {
        value: 'AG',
        text:
            "AG - Primary surgeon when there's more than one surgeon providing a service on the same patient. If more than one primary surgeon performing separate services on the same date of service, in different specialty areas."
    },
    { value: 'AI', text: 'AI - Principal physician of record' },
    { value: 'CC', text: 'CC - Procedure code change' },
    {
        value: 'CA',
        text:
            'CA - Procedure payable only in the inpatient setting when performed emergently on an outpatient who expires prior to admission'
    },
    {
        value: 'SU',
        text: "SU - Procedure performed in physician's office (to denote use of facility and equipment)"
    },
    { value: '63', text: '63 - Procedure Performed on Infants less than 4kg' },
    { value: '26', text: '26 - Professional component' },
    { value: 'TJ', text: 'TJ - Program group, child and/or adolescent' },
    { value: 'PL', text: 'PL - PROGRESSIVE ADDITION LENSES' },
    { value: '21', text: '21 - Prolonged E & M Services' },
    {
        value: 'LI',
        text: 'LI - Provider attestation that the hospital laboratory test(s) is not packaged under the hospital OPPS'
    },
    { value: 'RI', text: 'RI - Ramus Intermedius' },
    {
        value: 'GK',
        text: 'GK - Reasonable and necessary item/service associated with a ga or gz modifier'
    },
    { value: 'QD', text: 'QD - Recording and storage in solid state memory by digital recorder' },
    { value: 'QT', text: 'QT - Recording and Storage on a tape' },
    { value: '52', text: '52 - Reduced services' },
    { value: '90', text: '90 - Referenced (outside) laboratory' },
    { value: 'AE', text: 'AE - Registered dietitian' },
    { value: '97', text: '97 - Rehabilitative Services' },
    { value: 'ST', text: 'ST - Related to trauma or injury' },
    { value: 'RR', text: 'RR - Rental' },
    { value: 'KR', text: 'KR - Rental item, billing for partial month' },
    { value: '91', text: '91 - Repeat clinical diagnostic laboratory test' },
    { value: '77', text: '77 - Repeat procedure by another physician' },
    { value: '76', text: '76 - Repeat procedure by same physician' },
    { value: 'RP', text: 'RP - Replacement and repair' },
    { value: 'KM', text: 'KM - Replacement of facial prosthesis including new impression/moulage' },
    { value: 'KN', text: 'KN - Replacement of facial prosthesis using previous master model' },
    { value: 'KC', text: 'KC - Replacement of special power wheelchair interface' },
    { value: 'KX', text: 'KX - Requirements specified in the medical policy have been met' },
    { value: 'RH', text: 'RH - Residence to a Hospital' },
    { value: 'RJ', text: 'RJ - Residence to Freestanding ESRD facility' },
    { value: 'EH', text: 'EH - Residential/Domiciliary/Custodial fac. to a Hospital' },
    {
        value: 'EG',
        text: 'EG - Residential/Domiciliary/Custodial fac. to a Hospital-based Dialysis Facility'
    },
    { value: 'ER', text: 'ER - Residential/Domiciliary/Custodial fac. to a Residence' },
    {
        value: 'ES',
        text: 'ES - Residential/Domiciliary/Custodial fac. to a Scene of Accident/Acute Event'
    },
    {
        value: 'EI',
        text: 'EI - Residential/Domiciliary/Custodial fac. to a Site of ambulance transport modes transfer'
    },
    {
        value: 'EN',
        text: 'EN - Residential/Domiciliary/Custodial fac. to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'EX',
        text: "EX - Residential/Domiciliary/Custodial fac. to an Intermediate Stop at Physician's Office"
    },
    { value: 'RC', text: 'RC - Right coronary artery' },
    { value: 'RT', text: 'RT - Right side' },
    { value: 'TD', text: 'TD - RN' },
    {
        value: 'Q1',
        text:
            'Q1 - Routine clinical service provided in a clinical research study that is in an approved clinical research study'
    },
    { value: 'TN', text: 'TN - Rural/Outside providers customary service area' },
    { value: 'TR', text: 'TR - School-based Individual Education Program (IEP) services provided' },
    { value: 'SH', text: 'SH - Second concurrently administered infusion therapy' },
    { value: 'SF', text: 'SF - Second opinion ordered by a Professional Review Organization' },
    { value: 'KQ', text: 'KQ - Second or subsequent drug of a multiple drug unit dose formulation' },
    { value: 'SM', text: 'SM - Second surgical opinion' },
    { value: 'L1', text: 'L1 - Separately Payable Lab Test' },
    {
        value: 'Q4',
        text: 'Q4 - Service for ordering/referring physician qualifies as a service exemption'
    },
    {
        value: 'Q5',
        text: 'Q5 - Service furnished by a substitute physician under a reciprocal billing arrangement'
    },
    { value: 'GW', text: 'GW - Service not related to the hospice patient s terminal condition' },
    {
        value: 'CB',
        text:
            'CB - Service ordered by a RDF physician as part End Stage Renal Disease (ESRD) beneficiary s dialysis benefit'
    },
    {
        value: 'GE',
        text:
            'GE - Service performed by a resident without the presence of a teaching physician under the primary care exception'
    },
    {
        value: 'GC',
        text: 'GC - Service performed in part by a resident under the direction of a teaching physician'
    },
    {
        value: 'EP',
        text:
            'EP - Service provided as part of Medicaid early periodic screening diagnosis and treatment (EPSDT) program'
    },
    { value: 'FP', text: 'FP - Service Provided as part of medicaid family planning program' },
    { value: 'Q6', text: 'Q6 - Services furnished by a locum tenens physician' },
    { value: 'UJ', text: 'UJ - Services provided at night 7pm - 7am by medical transportation' },
    { value: 'SW', text: 'SW - SERVICES PROVIDED BY A CERTIFIED DIABETIC' },
    {
        value: 'SD',
        text: 'SD - Services provided by registered nurse with specialized, highly technical home infusion'
    },
    { value: 'UG', text: 'UG - Services provided in the afternoon' },
    { value: 'UH', text: 'UH - Services provided in the evening' },
    { value: 'UF', text: 'UF - Services provided in the morning' },
    {
        value: 'UK',
        text: 'UK - Services provided on behalf of beneficiary to someone other than the beneficiary'
    },
    {
        value: 'PO',
        text:
            'PO - Services, procedures, and/or surgeries furnished at off-campus provider-based outpatient departments'
    },
    { value: 'SC', text: 'SC - Single Chamber and Dual Chamber Permanent Cardiac Pacemakers' },
    { value: 'QC', text: 'QC - Single channel monitoring' },
    { value: 'KO', text: 'KO - Single drug unit dose formulation' },
    {
        value: 'ID',
        text: 'ID - Site of ambulance transport modes transfer to a Diagnostic/therapeutic site other than P/H'
    },
    { value: 'IH', text: 'IH - Site of ambulance transport modes transfer to a Hospital' },
    {
        value: 'IG',
        text: 'IG - Site of ambulance transport modes transfer to a Hospital-based Dialysis Facility'
    },
    {
        value: 'IJ',
        text: 'IJ - Site of ambulance transport modes transfer to a Non-Hospital-based Dialysis facility'
    },
    { value: 'IP', text: "IP - Site of ambulance transport modes transfer to a Physician's office" },
    { value: 'IR', text: 'IR - Site of ambulance transport modes transfer to a Residence' },
    {
        value: 'IE',
        text: 'IE - Site of ambulance transport modes transfer to a Residential/Domiciliary/Custodial fac.'
    },
    {
        value: 'IS',
        text: 'IS - Site of ambulance transport modes transfer to a Scene of Accident/Acute Event'
    },
    {
        value: 'IN',
        text: 'IN - Site of ambulance transport modes transfer to a Skilled Nursing Facility (SNF)'
    },
    {
        value: 'IX',
        text: "IX - Site of ambulance transport modes transfer to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'II',
        text: 'II - Site of ambulance transport modes transfer to another Site of ambulance transport modes transfer'
    },
    {
        value: 'ND',
        text: 'ND - Skilled Nursing Facility (SNF) to a Diagnostic/therapeutic site other than P/H'
    },
    { value: 'NH', text: 'NH - Skilled Nursing Facility (SNF) to a Hospital' },
    { value: 'NG', text: 'NG - Skilled Nursing Facility (SNF) to a Hospital-based Dialysis Facility' },
    {
        value: 'NJ',
        text: 'NJ - Skilled Nursing Facility (SNF) to a Non-Hospital-based Dialysis facility'
    },
    { value: 'NP', text: 'NP - Skilled Nursing Facility (SNF) to a Physicians office' },
    { value: 'NR', text: 'NR - Skilled Nursing Facility (SNF) to a Residence' },
    {
        value: 'NE',
        text: 'NE - Skilled Nursing Facility (SNF) to a Residential/Domiciliary/Custodial fac.'
    },
    { value: 'NS', text: 'NS - Skilled Nursing Facility (SNF) to a Scene of Accident/Acute Event' },
    {
        value: 'NI',
        text: 'NI - Skilled Nursing Facility (SNF) to a Site of ambulance transport modes transfer'
    },
    {
        value: 'NX',
        text: "NX - Skilled Nursing Facility (SNF) to an Intermediate Stop at Physician's Office"
    },
    {
        value: 'NN',
        text: 'NN - Skilled Nursing Facility (SNF) to another Skilled Nursing Facility (SNF)'
    },
    { value: 'JC', text: 'JC - Skin substitute used as a graft' },
    { value: 'BL', text: 'BL - Special acquisition of blood and blood products' },
    { value: 'TU', text: 'TU - Special payment rate, overtime' },
    { value: 'TV', text: 'TV - Special payment rates, holidays/weekends' },
    { value: 'HK', text: 'HK - Specialized mental health programs for high-risk populations' },
    { value: 'AF', text: 'AF - Specialty Physician Provided Service' },
    {
        value: '58',
        text: '58 - Staged or related procedure or service by the same physician during the postoperative period'
    },
    { value: 'SL', text: 'SL - STATE SUPPLIED VACCINE' },
    { value: 'U5', text: 'U5 - State-defined modifier for use with case management services.' },
    {
        value: 'EJ',
        text: 'EJ - Subsequent claims for a defined course of therapy, e.g., epo, sodium hyaluronate, inflaximab'
    },
    { value: 'HF', text: 'HF - Substance abuse' },
    { value: '1B', text: '1B - Support Personal Assistant Rate' },
    {
        value: 'PA',
        text: 'PA - Surgery wrong body part. Wrong surgical or other invasive procedures performed on a patient'
    },
    {
        value: 'PB',
        text: 'PB - Surgery wrong patient. Wrong surgical or other invasive procedures performed on a patient'
    },
    { value: '54', text: '54 - Surgical care only' },
    { value: '66', text: '66 - Surgical team' },
    {
        value: '95',
        text:
            '95 - Synchronous Telemedicine Service Rendered via Real-Time Interactive Audio and Video Telecommunications System'
    },
    { value: 'TC', text: 'TC - Technical component' },
    { value: 'G0', text: 'G0 - Telestroke' },
    {
        value: 'BU',
        text:
            'BU - The beneficiary has been informed of the purchase and rental options and after 30 days has not informed the supplier of his/her decision'
    },
    {
        value: 'BP',
        text:
            'BP - The beneficiary has been informed of the purchase and rental options and has elected to purchase the item'
    },
    {
        value: 'BR',
        text:
            'BR - The beneficiary has been informed of the purchase and rental options and has elected to rent the item'
    },
    {
        value: 'XU',
        text:
            'XU - The Use Of A Service That Is Distinct Because It Does Not Overlap Usual Components Of The Main Service'
    },
    { value: 'SJ', text: 'SJ - Third or more concurrently administered infusion therapy' },
    { value: 'SN', text: 'SN - Third surgical opinion' },
    { value: 'Q8', text: 'Q8 - Two class B findings' },
    { value: '62', text: '62 - Two surgeons' },
    {
        value: 'GD',
        text:
            'GD - Units of service exceeds medically unlikely edit value and represents reasonable and necessary services'
    },
    {
        value: '78',
        text:
            '78 - Unplanned return to the operating/procedure room by the same physician following initial procedure for a related procedure during the postoperative period'
    },
    {
        value: '79',
        text: '79 - Unrelated procedure or service by the same physician during the postoperative period'
    },
    { value: '22', text: '22 - Unusual procedural services' },
    { value: 'E1', text: 'E1 - Upper left eyelid' },
    { value: 'E3', text: 'E3 - Upper right eyelid' },
    { value: 'UE', text: 'UE - Used durable medical equipment' },
    { value: 'UE', text: 'UE - Used durable medical equipment purchase' },
    { value: 'GQ', text: 'GQ - Via asynchronous telecommunications system' },
    { value: 'GT', text: 'GT - Via interactive audio and video telecommunications system' },
    { value: 'GB', text: 'GB - Waiver of liability statement' },
    { value: 'GU', text: 'GU - WAIVER OF LIABILITY STATEMENT ISSUED AS REQUIRED' },
    {
        value: 'PC',
        text: 'PC - Wrong surgery on patient. Wrong surgical or other invasive procedures performed on a patient'
    },
    { value: 'FX', text: 'FX - Xray using Film' }
];

export const BillTypeOptions = [
    { value: '131', text: 'Hospital, Outpatient, Admit thru Discharge Claim' },
    { value: '8XE', text: 'IDK' },
    { value: '8XD', text: 'Cancel NOE/benefit period' },
    { value: '8XC', text: 'Change of hospice' },
    {
        value: '77A',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), ADMISSION/ELECTION NOTICE'
    },
    {
        value: '771',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), ADMIT THROUGH DISCHARGE CLAIM'
    },
    {
        value: '77F',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), BENEFICIARY INITIATED ADJUSTMENT CLAIM'
    },
    {
        value: '77Q',
        text:
            'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), CLAIM SUBMITTED FOR RECONSIDERATION/REOPENING OUTSIDE OF TIMELY LIMITS (EFFECTIVE 1/1/16)'
    },
    {
        value: '77H',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), CMS INITIATED ADJUSTMENT'
    },
    {
        value: '77G',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), CWF INITIATED ADJUSTMENT CLAIM'
    },
    {
        value: '779',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), FINAL CLAIM FOR A HOME HEALTH PPS EPISODE'
    },
    {
        value: '77E',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), HOSPICE CHANGE OF OWNERSHIP'
    },
    {
        value: '77C',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), HOSPICE CHANGE OF PROVIDER NOTICE'
    },
    {
        value: '77B',
        text:
            'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), HOSPICE/CMS COORDINATED CARE DEMONSTRATION/RELIGIOUS NON-MEDICAL HEALTH CARE INSTITUTION/CENTERS OF EXCELLENCE DEMONSTRATION/ PROVIDER PARTNERSHIPS DEMONSTRATION'
    },
    {
        value: '77D',
        text:
            'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), HOSPICE/CMS COORDINATED CARE DEMONSTRATION/RELIGIOUS NON-MEDICAL HEALTH CARE INSTITUTION/CENTERS OF EXCELLENCE DEMONSTRATION/ PROVIDER PARTNERSHIPS DEMONSTRATION VOID/CANCEL'
    },
    {
        value: '77J',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), INITIATED ADJUSTMENT CLAIM - OTHER'
    },
    {
        value: '773',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), INTERIM - CONTINUING CLAIM'
    },
    { value: '772', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), INTERIM - FIRST CLAIM' },
    { value: '774', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), INTERIM - LAST CLAIM' },
    {
        value: '77I',
        text:
            'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), INTERMEDIARY ADJUSTMENT CLAIM (OTHER THAN QIO OR PROVIDER)'
    },
    { value: '775', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), LATE CHARGE(S) ONLY' },
    {
        value: '77M',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), MSP INITIATED ADJUSTMENT CLAIM'
    },
    {
        value: '77Z',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), NEW ABBREVIATED ENCOUNTER SUBMISSION'
    },
    { value: '770', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), NON-PAYMENT/ZERO' },
    { value: '77O', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), NONPAYMENT/ZERO CLAIMS' },
    {
        value: '77K',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), OIG INITIATED ADJUSTMENT CLAIM'
    },
    { value: '77P', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), QIO ADJUSTMENT CLAIM' },
    {
        value: '77Y',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), REPLACEMENT OF PRIOR ABBREVIATED ENCOUNTER SUBMISSION'
    },
    {
        value: '777',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), REPLACEMENT OF PRIOR CLAIM'
    },
    {
        value: '77X',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), VOID/CANCEL A PRIOR ABBREVIATED ENCOUNTER SUBMISSION'
    },
    {
        value: '778',
        text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC), VOID/CANCEL OF PRIOR CLAIM'
    },
    { value: '77Q', text: 'CLINIC - FEDERALLY QUALIFIED HEALTH CENTER (FQHC) - Reopening Claim' },
    { value: '76Q', text: 'Clinic, Community Mental Health Center - Reopening Claim' },
    { value: '76A', text: 'Clinic, Community Mental Health Center, Admission/Election Notice' },
    { value: '761', text: 'Clinic, Community Mental Health Center, Admit through Discharge Claim' },
    {
        value: '76F',
        text: 'Clinic, Community Mental Health Center, Beneficiary Initiated Adjustment Claim'
    },
    { value: '76G', text: 'Clinic, Community Mental Health Center, CWF Initiated Adjustment Claim' },
    {
        value: '769',
        text: 'Clinic, Community Mental Health Center, Final Claim for a Home Health PPS Episode'
    },
    { value: '76H', text: 'Clinic, Community Mental Health Center, HCFA Initiated Adjustment Claim' },
    { value: '76E', text: 'Clinic, Community Mental Health Center, Hospice Change of Ownership' },
    { value: '76C', text: 'Clinic, Community Mental Health Center, Hospice Change of Provider Notice' },
    {
        value: '76B',
        text:
            'Clinic, Community Mental Health Center, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '76D',
        text:
            'Clinic, Community Mental Health Center, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    { value: '76J', text: 'Clinic, Community Mental Health Center, Initiated Adjustment Claim' },
    { value: '762', text: 'Clinic, Community Mental Health Center, Interim - First Claim' },
    {
        value: '763',
        text: 'Clinic, Community Mental Health Center, Interim-Continuing Claims (Not valid for PPS Bills)'
    },
    {
        value: '764',
        text: 'Clinic, Community Mental Health Center, Interim-Last Claim (Not valid for PPS Bills)'
    },
    { value: '76I', text: 'Clinic, Community Mental Health Center, Intermediary Adjustment Claim' },
    { value: '765', text: 'Clinic, Community Mental Health Center, Late Charge Only' },
    { value: '76M', text: 'Clinic, Community Mental Health Center, MSP Initiated Adjustment Claim' },
    {
        value: '76Z',
        text: 'Clinic, Community Mental Health Center, New Abbreviated Encounter Submission'
    },
    { value: '760', text: 'Clinic, Community Mental Health Center, Non-payment/Zero Claim' },
    { value: '76O', text: 'Clinic, Community Mental Health Center, Non-payment/Zero Claims' },
    { value: '76K', text: 'Clinic, Community Mental Health Center, OIG Initiated Adjustment Claim' },
    { value: '76N', text: 'Clinic, Community Mental Health Center, PRO Adjustment Claim' },
    {
        value: '76Y',
        text: 'Clinic, Community Mental Health Center, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '767', text: 'Clinic, Community Mental Health Center, Replacement of a Prior Claim' },
    {
        value: '76X',
        text: 'Clinic, Community Mental Health Center, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '768', text: 'Clinic, Community Mental Health Center, Void/Cancel of a Prior Claim' },
    {
        value: '75Q',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs) - Reopening Claim'
    },
    {
        value: '751',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Admit thru Discharge Claim'
    },
    {
        value: '75F',
        text:
            'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '75G',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), CWF Initiated Adjustment Claim'
    },
    {
        value: '75H',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), HCFA Initiated Adjustment Claim'
    },
    {
        value: '75J',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Initiated Adjustment Claim - Other'
    },
    {
        value: '752',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Interim - First Claim'
    },
    {
        value: '753',
        text:
            'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Interim-Continuing Claims (Not valid for PPS Bills)'
    },
    {
        value: '754',
        text:
            'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Interim-Last Claim (Not valid for PPS Bills)'
    },
    {
        value: '75I',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Intermediary Adjustment Claim'
    },
    {
        value: '755',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Late Charge(s) Only Claim'
    },
    {
        value: '75M',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), MSP Initiated Adjustment Claim'
    },
    {
        value: '75Z',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), New Abbreviated Encounter Submission'
    },
    {
        value: '750',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Non-Payment/Zero Claim'
    },
    {
        value: '75O',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Non-payment/Zero Claims'
    },
    {
        value: '75K',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), OIG Initiated Adjustment Claim'
    },
    {
        value: '75N',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), PRO Adjustment Claim'
    },
    {
        value: '75Y',
        text:
            'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '757',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Replacement of Prior Claim'
    },
    {
        value: '75X',
        text:
            'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '758',
        text: 'Clinic, Comprehensive Outpatient Rehabilitation Facilities (CORFs), Void/Cancel of Prior Claim'
    },
    { value: '73Q', text: 'Clinic, Free Standing - Reopening Claim' },
    { value: '731', text: 'Clinic, Free Standing, Admit thru Discharge Claim' },
    { value: '73F', text: 'Clinic, Free Standing, Beneficiary Initiated Adjustment Claim' },
    { value: '73G', text: 'Clinic, Free Standing, CWF Initiated Adjustment Claim' },
    { value: '73H', text: 'Clinic, Free Standing, HCFA Initiated Adjustment Claim' },
    { value: '73J', text: 'Clinic, Free Standing, Initiated Adjustment Claim - Other' },
    { value: '732', text: 'Clinic, Free Standing, Interim - First Claim' },
    {
        value: '733',
        text: 'Clinic, Free Standing, Interim-Continuing Claims (Not valid for PPS Bills)'
    },
    { value: '734', text: 'Clinic, Free Standing, Interim-Last Claim (Not valid for PPS Bills)' },
    { value: '73I', text: 'Clinic, Free Standing, Intermediary Adjustment Claim' },
    { value: '735', text: 'Clinic, Free Standing, Late Charge(s) Only Claim' },
    { value: '73M', text: 'Clinic, Free Standing, MSP Initiated Adjustment Claim' },
    { value: '73Z', text: 'Clinic, Free Standing, New Abbreviated Encounter Submission' },
    { value: '730', text: 'Clinic, Free Standing, Non-Payment/Zero Claim' },
    { value: '73O', text: 'Clinic, Free Standing, Non-payment/Zero Claims' },
    { value: '73K', text: 'Clinic, Free Standing, OIG Initiated Adjustment Claim' },
    { value: '73N', text: 'Clinic, Free Standing, PRO Adjustment Claim' },
    {
        value: '73Y',
        text: 'Clinic, Free Standing, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '737', text: 'Clinic, Free Standing, Replacement of Prior Claim' },
    {
        value: '73X',
        text: 'Clinic, Free Standing, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '738', text: 'Clinic, Free Standing, Void/Cancel of Prior Claim' },
    {
        value: '72Q',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center - Reopening Claim'
    },
    {
        value: '721',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Admit thru Discharge Claim'
    },
    {
        value: '72F',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '72G',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, CWF Initiated Adjustment Claim'
    },
    {
        value: '72H',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, HCFA Initiated Adjustment Claim'
    },
    {
        value: '72J',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Initiated Adjustment Claim'
    },
    {
        value: '722',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Interim - First Claim'
    },
    {
        value: '723',
        text:
            'Clinic, Hospital Based or Independent Renal Dialysis Center, Interim-Continuing Claims (Not valid for PPS Bills)'
    },
    {
        value: '724',
        text:
            'Clinic, Hospital Based or Independent Renal Dialysis Center, Interim-Last Claim (Not valid for PPS Bills)'
    },
    {
        value: '72I',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Intermediary Adjustment Claim'
    },
    {
        value: '725',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Late Charge(s) Only Claim'
    },
    {
        value: '72M',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, MSP Initiated Adjustment Claim'
    },
    {
        value: '72Z',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, New Abbreviated Encounter Submission'
    },
    {
        value: '720',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Non-Payment/Zero Claim'
    },
    {
        value: '72O',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Non-payment/Zero Claims'
    },
    {
        value: '72K',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, OIG Initiated Adjustment Claim'
    },
    {
        value: '72N',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, PRO Adjustment Claim'
    },
    {
        value: '72Y',
        text:
            'Clinic, Hospital Based or Independent Renal Dialysis Center, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '727',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Replacement of Prior Claim'
    },
    {
        value: '72X',
        text:
            'Clinic, Hospital Based or Independent Renal Dialysis Center, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '728',
        text: 'Clinic, Hospital Based or Independent Renal Dialysis Center, Void/Cancel of Prior Claim'
    },
    { value: '79Q', text: 'Clinic, Other - Reopening Claim' },
    { value: '79A', text: 'Clinic, Other, Admission/Election Notice' },
    { value: '791', text: 'Clinic, Other, Admit thru Discharge Claim' },
    { value: '79F', text: 'Clinic, Other, Beneficiary Initiated Adjustment Claim' },
    { value: '79G', text: 'Clinic, Other, CWF Initiated Adjustment Claim' },
    { value: '79H', text: 'Clinic, Other, HCFA Initiated Adjustment Claim' },
    { value: '79E', text: 'Clinic, Other, Hospice Change of Ownership' },
    { value: '79C', text: 'Clinic, Other, Hospice Change of Provider Notice' },
    {
        value: '79B',
        text:
            'Clinic, Other, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '79D',
        text:
            'Clinic, Other, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    { value: '79J', text: 'Clinic, Other, Initiated Adjustment Claim' },
    { value: '792', text: 'Clinic, Other, Interim - First Claim' },
    { value: '793', text: 'Clinic, Other, Interim-Continuing Claims (Not valid for PPS Bills)' },
    { value: '794', text: 'Clinic, Other, Interim-Last Claim (Not valid for PPS Bills)' },
    { value: '79I', text: 'Clinic, Other, Intermediary Adjustment Claim' },
    { value: '795', text: 'Clinic, Other, Late Charge(s) Only Claim' },
    { value: '79M', text: 'Clinic, Other, MSP Initiated Adjustment Claim' },
    { value: '79Z', text: 'Clinic, Other, New Abbreviated Encounter Submission' },
    { value: '790', text: 'Clinic, Other, Non-Payment/Zero Claim' },
    { value: '79O', text: 'Clinic, Other, Non-payment/Zero Claims' },
    { value: '79K', text: 'Clinic, Other, OIG Initiated Adjustment Claim' },
    { value: '79N', text: 'Clinic, Other, PRO Adjustment Claim' },
    { value: '79Y', text: 'Clinic, Other, Replacement of a Prior Abbreviated Encounter Submission' },
    { value: '797', text: 'Clinic, Other, Replacement of Prior Claim' },
    { value: '79X', text: 'Clinic, Other, Void/Cancel of a Prior Abbreviated Encounter Submission' },
    { value: '798', text: 'Clinic, Other, Void/Cancel of Prior Claim' },
    { value: '74Q', text: 'Clinic, Outpatient Rehabilitation Facility (ORF) - Reopening Claim' },
    {
        value: '741',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Admit thru Discharge Claim'
    },
    {
        value: '74F',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '74G',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), CWF Initiated Adjustment Claim'
    },
    {
        value: '74H',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), HCFA Initiated Adjustment Claim'
    },
    {
        value: '74J',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Initiated Adjustment Claim - Other'
    },
    { value: '742', text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Interim - First Claim' },
    {
        value: '743',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Interim-Continuing Claims (Not valid for PPS Bills)'
    },
    {
        value: '744',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Interim-Last Claim (Not valid for PPS Bills)'
    },
    {
        value: '74I',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Intermediary Adjustment Claim'
    },
    {
        value: '745',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Late Charge(s) Only Claim'
    },
    {
        value: '74M',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), MSP Initiated Adjustment Claim'
    },
    {
        value: '74Z',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), New Abbreviated Encounter Submission'
    },
    { value: '740', text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Non-Payment/Zero Claim' },
    { value: '74O', text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Non-payment/Zero Claims' },
    {
        value: '74K',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), OIG Initiated Adjustment Claim'
    },
    { value: '74N', text: 'Clinic, Outpatient Rehabilitation Facility (ORF), PRO Adjustment Claim' },
    {
        value: '74Y',
        text:
            'Clinic, Outpatient Rehabilitation Facility (ORF), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '747',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Replacement of Prior Claim'
    },
    {
        value: '74X',
        text:
            'Clinic, Outpatient Rehabilitation Facility (ORF), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '748',
        text: 'Clinic, Outpatient Rehabilitation Facility (ORF), Void/Cancel of Prior Claim'
    },
    { value: '71Q', text: 'Clinic, Rural Health - Reopening Claim' },
    { value: '711', text: 'Clinic, Rural Health, Admit thru Discharge Claim' },
    { value: '71F', text: 'Clinic, Rural Health, Beneficiary Initiated Adjustment Claim' },
    { value: '71G', text: 'Clinic, Rural Health, CWF Initiated Adjustment Claim' },
    { value: '71H', text: 'Clinic, Rural Health, HCFA Initiated Adjustment Claim' },
    { value: '71J', text: 'Clinic, Rural Health, Initiated Adjustment Claim' },
    { value: '71I', text: 'Clinic, Rural Health, Intermediary Adjustment Claim' },
    { value: '715', text: 'Clinic, Rural Health, Late Charge(s) Only Claim' },
    { value: '71M', text: 'Clinic, Rural Health, MSP Initiated Adjustment Claim' },
    { value: '71Z', text: 'Clinic, Rural Health, New Abbreviated Encounter Submission' },
    { value: '71O', text: 'Clinic, Rural Health, Non-payment/Zero Claims' },
    { value: '710', text: 'Clinic, Rural Health, Non-payment/Zero Claims, Non-payment/Zero Claims' },
    { value: '71K', text: 'Clinic, Rural Health, OIG Initiated Adjustment Claim' },
    { value: '71N', text: 'Clinic, Rural Health, PRO Adjustment Claim' },
    {
        value: '71Y',
        text: 'Clinic, Rural Health, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '717', text: 'Clinic, Rural Health, Replacement of Prior Claim' },
    {
        value: '71X',
        text: 'Clinic, Rural Health, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '718', text: 'Clinic, Rural Health, Void/Cancel of Prior Claim' },
    {
        value: '78J',
        text: 'Freestanding Emergency Medical Facility, Other, Initiated Adjustment Claim – Other'
    },
    {
        value: '78I',
        text: 'Freestanding Emergency Medical Facility, Other, Intermediary Adjustment Claim'
    },
    { value: '874', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '871', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '873', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '873', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '875', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '876', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '877', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '878', text: 'FREESTANDING NON-RESIDENTIAL OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: '32Q', text: 'Home Health, Inpatient (Medicare Part B only) - Reopening Claim' },
    { value: '32A', text: 'Home Health, Inpatient (Medicare Part B only), Admission/Election Notice' },
    { value: '321', text: 'Home Health, Inpatient (Medicare Part B only), Admit thru Discharge Claim' },
    {
        value: '32F',
        text: 'Home Health, Inpatient (Medicare Part B only), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '32G',
        text: 'Home Health, Inpatient (Medicare Part B only), CWF Initiated Adjustment Claim'
    },
    {
        value: '329',
        text:
            'Home Health, Inpatient (Medicare Part B only), Final Claim for a Home Health PPS Period (Eff 12/31/21 Description changed from Episode to Period)'
    },
    {
        value: '32H',
        text: 'Home Health, Inpatient (Medicare Part B only), HCFA Initiated Adjustment Claim'
    },
    {
        value: '32E',
        text: 'Home Health, Inpatient (Medicare Part B only), Hospice Change of Ownership'
    },
    {
        value: '32C',
        text: 'Home Health, Inpatient (Medicare Part B only), Hospice Change of Provider Notice'
    },
    {
        value: '32B',
        text:
            'Home Health, Inpatient (Medicare Part B only), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '32D',
        text:
            'Home Health, Inpatient (Medicare Part B only), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    {
        value: '32J',
        text: 'Home Health, Inpatient (Medicare Part B only), Initiated Adjustment Claim - Other'
    },
    { value: '323', text: 'Home Health, Inpatient (Medicare Part B only), Interim - Continuing Claim' },
    { value: '322', text: 'Home Health, Inpatient (Medicare Part B only), Interim - First Claim' },
    { value: '324', text: 'Home Health, Inpatient (Medicare Part B only), Interim - Last Claim' },
    {
        value: '32I',
        text:
            'Home Health, Inpatient (Medicare Part B only), Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    { value: '325', text: 'Home Health, Inpatient (Medicare Part B only), Late Charge Only' },
    {
        value: '32M',
        text: 'Home Health, Inpatient (Medicare Part B only), MSP Initiated Adjustment Claim'
    },
    {
        value: '32Z',
        text: 'Home Health, Inpatient (Medicare Part B only), New Abbreviated Encounter Submission'
    },
    { value: '320', text: 'Home Health, Inpatient (Medicare Part B only), Non-Payment/Zero Claim' },
    { value: '32O', text: 'Home Health, Inpatient (Medicare Part B only), Non-payment/Zero Claims' },
    {
        value: '32K',
        text: 'Home Health, Inpatient (Medicare Part B only), OIG Initiated Adjustment Claim'
    },
    { value: '32N', text: 'Home Health, Inpatient (Medicare Part B only), PRO Adjustment Claim' },
    {
        value: '32Y',
        text: 'Home Health, Inpatient (Medicare Part B only), Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '327', text: 'Home Health, Inpatient (Medicare Part B only), Replacement of Prior Claim' },
    {
        value: '32X',
        text: 'Home Health, Inpatient (Medicare Part B only), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '328', text: 'Home Health, Inpatient (Medicare Part B only), Void/Cancel of Prior Claim' },
    {
        value: '34Q',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment) - Reopening Claim'
    },
    {
        value: '34A',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Admission/Election Notice'
    },
    {
        value: '341',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Admit thru Discharge Claim'
    },
    {
        value: '34F',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '34G',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), CWF Initiated Adjustment Claim'
    },
    {
        value: '349',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Final Claim for a Home Health PPS Period (Eff 12/31/21 Description changed from Episode to Period)'
    },
    {
        value: '34H',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), HCFA Initiated Adjustment Claim'
    },
    {
        value: '34E',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Hospice Change of Ownership'
    },
    {
        value: '34C',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Hospice Change of Provider Notice'
    },
    {
        value: '34B',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Hospice/Medicare Coordinated Care Demonstration/Religious Non- medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '34D',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Hospice/Medicare Coordinated Care Demonstration/Religious Non- medical Health Care Institution-Void/Cancel'
    },
    {
        value: '34J',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Initiated Adjustment Claim'
    },
    {
        value: '343',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Interim - Continuing Claim'
    },
    {
        value: '342',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Interim - First Claim'
    },
    {
        value: '344',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Interim - Last Claim'
    },
    {
        value: '34I',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Intermediary Adjustment Claim'
    },
    {
        value: '345',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Late Charge(s) Only Claim'
    },
    {
        value: '34M',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), MSP Initiated Adjustment Claim'
    },
    {
        value: '34Z',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), New Abbreviated Encounter Submission'
    },
    {
        value: '340',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Non-Payment/Zero Claim'
    },
    {
        value: '34O',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Non-payment/Zero Claims'
    },
    {
        value: '34K',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), OIG Initiated Adjustment Claim'
    },
    {
        value: '34N',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), PRO Adjustment Claim'
    },
    {
        value: '34Y',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '347',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Replacement of Prior Claim'
    },
    {
        value: '34X',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '348',
        text:
            'Home Health, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Void/Cancel of a Prior Claim'
    },
    {
        value: '339',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Final Claim for a Home Health PPS Period (Eff 12/31/21 Description changed from Episode to Period)'
    },
    {
        value: '33A',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Admission/Election Notice'
    },
    {
        value: '331',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Admit thru Discharge Claim'
    },
    {
        value: '33F',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Beneficiary Initiated Adjustment'
    },
    {
        value: '33G',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), CWF Initiated Adjustment Claim'
    },
    {
        value: '33H',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), HCFA Initiated Adjustment Claim'
    },
    {
        value: '33E',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Hospice Change of Ownership'
    },
    {
        value: '33C',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Hospice Change of Provider Notice'
    },
    {
        value: '33J',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Initiated Adjustment Claim - Other'
    },
    {
        value: '333',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Interim - Continuing Claim'
    },
    {
        value: '332',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Interim - First Claim'
    },
    {
        value: '334',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Interim - Last Claim'
    },
    {
        value: '33I',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Intermediary Adjustment Claim'
    },
    {
        value: '335',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Late Charge(s) Only Claim'
    },
    {
        value: '33M',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), MSP Initiated Adjustment Claim'
    },
    {
        value: '33Z',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), New Abbreviated Encounter Submission'
    },
    {
        value: '330',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Non-Payment/Zero Claim'
    },
    {
        value: '33O',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Non-payment/Zero Claims'
    },
    {
        value: '33K',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), OIG Initiated Adjustment Claim'
    },
    {
        value: '33N',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), PRO Adjustment Claim'
    },
    {
        value: '33Y',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '337',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Replacement of Prior Claim'
    },
    {
        value: '33X',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '338',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Void/Cancel of Prior Claim'
    },
    {
        value: '33Q',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A) - Reopening Claim'
    },
    {
        value: '33B',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non- medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '33D',
        text:
            'Home Health, Outpatient (includes HHA visits under a Part A plan of treatment including DME under Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non- medical Health Care Institution-Void/Cancel'
    },
    { value: '11Q', text: 'Hospital, Inpatient (Including Medicare Part A) - Reopening Claim' },
    {
        value: '111',
        text: 'Hospital, Inpatient (Including Medicare Part A), Admit thru Discharge Claim'
    },
    {
        value: '11F',
        text: 'Hospital, Inpatient (Including Medicare Part A), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '11I',
        text: 'Hospital, Inpatient (Including Medicare Part A), Cancel of Prior Claim, Intermediary Adjustment Claim'
    },
    {
        value: '11H',
        text: 'Hospital, Inpatient (Including Medicare Part A), HCFA Initiated Adjustment Claim'
    },
    {
        value: '11J',
        text: 'Hospital, Inpatient (Including Medicare Part A), Initiated Adjustment Claim'
    },
    {
        value: '113',
        text:
            'Hospital, Inpatient (Including Medicare Part A), Interim - Continuing Claim (Used by non-PPS acute care facilities)'
    },
    {
        value: '112',
        text:
            'Hospital, Inpatient (Including Medicare Part A), Interim - First Claim (Used by non-PPS acute care facilities)'
    },
    {
        value: '114',
        text:
            'Hospital, Inpatient (Including Medicare Part A), Interim - Last Claim (Used by non-PPS acute care facilities)'
    },
    {
        value: '115',
        text:
            'Hospital, Inpatient (Including Medicare Part A), Late Charge(s) Only Claim (Use for inpatient Part A bill for ancillary services for non-PPS facilities)'
    },
    {
        value: '11M',
        text: 'Hospital, Inpatient (Including Medicare Part A), MSP Initiated Adjustment Claim'
    },
    {
        value: '11Z',
        text: 'Hospital, Inpatient (Including Medicare Part A), New Abbreviated Encounter Submission'
    },
    { value: '110', text: 'Hospital, Inpatient (Including Medicare Part A), Non-Payment/Zero Claim' },
    { value: '11O', text: 'Hospital, Inpatient (Including Medicare Part A), Non-payment/Zero Claims' },
    {
        value: '11K',
        text: 'Hospital, Inpatient (Including Medicare Part A), OIG Initiated Adjustment Claim'
    },
    { value: '11N', text: 'Hospital, Inpatient (Including Medicare Part A), PRO Adjustment Claim' },
    {
        value: '11Y',
        text: 'Hospital, Inpatient (Including Medicare Part A), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '117',
        text: 'Hospital, Inpatient (Including Medicare Part A), Replacement of Prior Claim'
    },
    {
        value: '118',
        text: 'Hospital, Inpatient (Including Medicare Part A), Void, Cancel of Prior Claim'
    },
    {
        value: '11G',
        text: 'Hospital, Inpatient (Including Medicare Part A), Void, CWF Initiated Adjustment Claim'
    },
    {
        value: '11X',
        text: 'Hospital, Inpatient (Including Medicare Part A), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '121', text: 'Hospital, Inpatient (Medicare Part B only), Admit thru Discharge Claim' },
    {
        value: '12F',
        text: 'Hospital, Inpatient (Medicare Part B only), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '12G',
        text: 'Hospital, Inpatient (Medicare Part B only), CWF Initiated Adjustment Claim'
    },
    {
        value: '12H',
        text: 'Hospital, Inpatient (Medicare Part B only), HCFA Initiated Adjustment Claim'
    },
    {
        value: '12J',
        text: 'Hospital, Inpatient (Medicare Part B only), Initiated Adjustment Claim - Other'
    },
    {
        value: '123',
        text: 'Hospital, Inpatient (Medicare Part B only), Interim - Continuing Claims (Not Valid for PPS Bills)'
    },
    { value: '122', text: 'Hospital, Inpatient (Medicare Part B only), Interim - First Claim' },
    {
        value: '124',
        text: 'Hospital, Inpatient (Medicare Part B only), Interim - Last Claim (Not Valid for PPS Bills)'
    },
    {
        value: '12I',
        text: 'Hospital, Inpatient (Medicare Part B only), Intermediary Adjustment Claim (Other than Pro or Provider)'
    },
    { value: '125', text: 'Hospital, Inpatient (Medicare Part B only), Late Charge Only' },
    {
        value: '12M',
        text: 'Hospital, Inpatient (Medicare Part B only), MSP Initiated Adjustment Claim'
    },
    {
        value: '12Z',
        text: 'Hospital, Inpatient (Medicare Part B only), New Abbreviated Encounter Submission'
    },
    { value: '12O', text: 'Hospital, Inpatient (Medicare Part B only), Non-payment/Zero Claims' },
    {
        value: '12K',
        text: 'Hospital, Inpatient (Medicare Part B only), OIG Initiated Adjustment Claim'
    },
    { value: '12N', text: 'Hospital, Inpatient (Medicare Part B only), PRO Adjustment Claim' },
    {
        value: '12Y',
        text: 'Hospital, Inpatient (Medicare Part B only), Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '127', text: 'Hospital, Inpatient (Medicare Part B only), Replacement of Prior Claim' },
    {
        value: '12X',
        text: 'Hospital, Inpatient (Medicare Part B only), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '128', text: 'Hospital, Inpatient (Medicare Part B only), Void/Cancel of Prior Claim' },
    {
        value: '141',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Admit thru Discharge Claim'
    },
    {
        value: '14F',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '14G',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), CWF Initiated Adjustment Claim'
    },
    {
        value: '14H',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), HCFA Initiated Adjustment Claim'
    },
    {
        value: '14J',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Initiated Adjustment Claim - Other'
    },
    {
        value: '14I',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    {
        value: '145',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Late Charge(s) Only Claim'
    },
    {
        value: '14M',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), MSP Initiated Adjustment Claim'
    },
    {
        value: '14Z',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), New Abbreviated Encounter Submission'
    },
    {
        value: '140',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Non-Payment/Zero Claim'
    },
    {
        value: '14O',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Non-payment/Zero Claims'
    },
    {
        value: '14K',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), OIG Initiated Adjustment Claim'
    },
    {
        value: '14N',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), PRO Adjustment Claim'
    },
    {
        value: '14Y',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '147',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Replacement of Prior Claim'
    },
    {
        value: '14X',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '148',
        text:
            'Hospital, Other (for hospital referenced diagnostic services, or home health not under a plan of treatment), Void/Cancel of Prior Claim'
    },
    { value: '14Q', text: 'Hospital, Other - Reopening Claim' },
    { value: '143', text: 'Hospital, Other, Interim-Continuing Claims (Not valid for PPS Bills)' },
    { value: '142', text: 'Hospital, Other, Interim-First Claim' },
    { value: '144', text: 'Hospital, Other, Interim-Last Claim (Not valid for PPS Bills)' },
    { value: '13Q', text: 'Hospital, Outpatient - Reopening Claim' },
    { value: '13F', text: 'Hospital, Outpatient, Beneficiary Initiated Adjustment Claim' },
    { value: '13G', text: 'Hospital, Outpatient, CWF Initiated Adjustment Claim' },
    { value: '13H', text: 'Hospital, Outpatient, HCFA Initiated Adjustment Claim' },
    { value: '13J', text: 'Hospital, Outpatient, Initiated Adjustment Claim - Other' },
    {
        value: '133',
        text: 'Hospital, Outpatient, Interim - Continuing Claims (Not Valid for PPS Bills)'
    },
    { value: '132', text: 'Hospital, Outpatient, Interim - First Claim' },
    { value: '134', text: 'Hospital, Outpatient, Interim - Last Claim (Not Valid for PPS Bills)' },
    {
        value: '13I',
        text: 'Hospital, Outpatient, Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    { value: '135', text: 'Hospital, Outpatient, Late Charge(s) Only Claim' },
    { value: '13M', text: 'Hospital, Outpatient, MSP Initiated Adjustment Claim' },
    { value: '13Z', text: 'Hospital, Outpatient, New Abbreviated Encounter Submission' },
    { value: '130', text: 'Hospital, Outpatient, Non-Payment/Zero Claim' },
    { value: '13O', text: 'Hospital, Outpatient, Non-payment/Zero Claims' },
    { value: '13K', text: 'Hospital, Outpatient, OIG Initiated Adjustment Claim' },
    { value: '13N', text: 'Hospital, Outpatient, PRO Adjustment Claim' },
    {
        value: '13Y',
        text: 'Hospital, Outpatient, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '137', text: 'Hospital, Outpatient, Replacement of Prior Claim' },
    {
        value: '13X',
        text: 'Hospital, Outpatient, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '138', text: 'Hospital, Outpatient, Void/Cancel of Prior Claim' },
    { value: '18Q', text: 'Hospital, Swing Beds - Reopening Claim' },
    { value: '181', text: 'Hospital, Swing Beds, Admit thru Discharge Claim' },
    { value: '18F', text: 'Hospital, Swing Beds, Beneficiary Initiated Adjustment Claim' },
    { value: '18G', text: 'Hospital, Swing Beds, CWF Initiated Adjustment Claim' },
    { value: '18H', text: 'Hospital, Swing Beds, HCFA Initiated Adjustment Claim' },
    { value: '18J', text: 'Hospital, Swing Beds, Initiated Adjustment Claim - Other' },
    { value: '183', text: 'Hospital, Swing Beds, Interim-Continuing Claims (Not valid for PPS Bills)' },
    { value: '182', text: 'Hospital, Swing Beds, Interim-First Claim' },
    { value: '184', text: 'Hospital, Swing Beds, Interim-Last Claim (Not valid for PPS Bills)' },
    {
        value: '18I',
        text: 'Hospital, Swing Beds, Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    {
        value: '185',
        text:
            'Hospital, Swing Beds, Late Charge(s) Only Claim (Used to bill inpatient Part A for inpatient ancillary services)'
    },
    { value: '18M', text: 'Hospital, Swing Beds, MSP Initiated Adjustment Claim' },
    { value: '18Z', text: 'Hospital, Swing Beds, New Abbreviated Encounter Submission' },
    { value: '180', text: 'Hospital, Swing Beds, Non-payment/Zero Claims' },
    { value: '18O', text: 'Hospital, Swing Beds, Non-payment/Zero Claims' },
    { value: '18K', text: 'Hospital, Swing Beds, OIG Initiated Adjustment Claim' },
    {
        value: '18Y',
        text: 'Hospital, Swing Beds, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '187', text: 'Hospital, Swing Beds, Replacement of a Prior Claim' },
    {
        value: '18X',
        text: 'Hospital, Swing Beds, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '188', text: 'Hospital, Swing Beds, Void/Cancel of a Prior Claim' },
    {
        value: '65F',
        text: 'Intermediate Care, Intermediate Care - Level 1, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '65J',
        text: 'Intermediate Care, Intermediate Care - Level 1, Initiated Adjustment Claim'
    },
    {
        value: '65I',
        text: 'Intermediate Care, Intermediate Care - Level 1, Intermediary Adjustment Claim'
    },
    {
        value: '65M',
        text: 'Intermediate Care, Intermediate Care - Level 1, MSP Initiated Adjustment Claim'
    },
    {
        value: '65Z',
        text: 'Intermediate Care, Intermediate Care - Level 1, New Abbreviated Encounter Submission'
    },
    { value: '65O', text: 'Intermediate Care, Intermediate Care - Level 1, Non-payment/Zero Claims' },
    {
        value: '65K',
        text: 'Intermediate Care, Intermediate Care - Level 1, OIG Initiated Adjustment Claim'
    },
    { value: '65N', text: 'Intermediate Care, Intermediate Care - Level 1, PRO Adjustment Claim' },
    {
        value: '65Y',
        text: 'Intermediate Care, Intermediate Care - Level 1, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '65X',
        text: 'Intermediate Care, Intermediate Care - Level 1, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '65Q', text: 'Intermediate Care, Intermediate Care - Level I - Reopening Claim' },
    {
        value: '651',
        text: 'Intermediate Care, Intermediate Care - Level I, Admit thru Discharge Claim'
    },
    {
        value: '653',
        text: 'Intermediate Care, Intermediate Care - Level I, Interim - Continuing Claim'
    },
    { value: '652', text: 'Intermediate Care, Intermediate Care - Level I, Interim - First Claim' },
    { value: '654', text: 'Intermediate Care, Intermediate Care - Level I, Interim - Last Claim' },
    { value: '655', text: 'Intermediate Care, Intermediate Care - Level I, Late Charge(s) Only Claim' },
    { value: '650', text: 'Intermediate Care, Intermediate Care - Level I, Non-Payment/Zero Claim' },
    {
        value: '657',
        text: 'Intermediate Care, Intermediate Care - Level I, Replacement of Prior Claim'
    },
    {
        value: '658',
        text: 'Intermediate Care, Intermediate Care - Level I, Void/Cancel of Prior Claim'
    },
    { value: '66Q', text: 'Intermediate Care, Intermediate Care - Level II - Reopening Claim' },
    {
        value: '661',
        text: 'Intermediate Care, Intermediate Care - Level II, Admit thru Discharge Claim'
    },
    {
        value: '66F',
        text: 'Intermediate Care, Intermediate Care - Level II, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '66G',
        text: 'Intermediate Care, Intermediate Care - Level II, CWF Initiated Adjustment Claim'
    },
    {
        value: '66H',
        text: 'Intermediate Care, Intermediate Care - Level II, HCFA Initiated Adjustment Claim'
    },
    {
        value: '66J',
        text: 'Intermediate Care, Intermediate Care - Level II, Initiated Adjustment Claim'
    },
    {
        value: '663',
        text: 'Intermediate Care, Intermediate Care - Level II, Interim - Continuing Claim'
    },
    { value: '662', text: 'Intermediate Care, Intermediate Care - Level II, Interim - First Claim' },
    { value: '664', text: 'Intermediate Care, Intermediate Care - Level II, Interim - Last Claim' },
    {
        value: '66I',
        text: 'Intermediate Care, Intermediate Care - Level II, Intermediary Adjustment Claim'
    },
    {
        value: '665',
        text: 'Intermediate Care, Intermediate Care - Level II, Late Charge(s) Only Claim'
    },
    {
        value: '66M',
        text: 'Intermediate Care, Intermediate Care - Level II, MSP Initiated Adjustment Claim'
    },
    {
        value: '66Z',
        text: 'Intermediate Care, Intermediate Care - Level II, New Abbreviated Encounter Submission'
    },
    { value: '660', text: 'Intermediate Care, Intermediate Care - Level II, Non-Payment/Zero Claim' },
    { value: '66O', text: 'Intermediate Care, Intermediate Care - Level II, Non-payment/Zero Claims' },
    {
        value: '66K',
        text: 'Intermediate Care, Intermediate Care - Level II, OIG Initiated Adjustment Claim'
    },
    { value: '66N', text: 'Intermediate Care, Intermediate Care - Level II, PRO Adjustment Claim' },
    {
        value: '66Y',
        text: 'Intermediate Care, Intermediate Care - Level II, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '667',
        text: 'Intermediate Care, Intermediate Care - Level II, Replacement of Prior Claim'
    },
    {
        value: '66X',
        text: 'Intermediate Care, Intermediate Care - Level II, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '668',
        text: 'Intermediate Care, Intermediate Care - Level II, Void/Cancel of Prior Claim'
    },
    {
        value: '65G',
        text: 'Intermediate Care, Intermediate Care - Level, CWF Initiated Adjustment Claim'
    },
    {
        value: '65H',
        text: 'Intermediate Care, Intermediate Care - Level, HCFA Initiated Adjustment Claim'
    },
    { value: '67Q', text: 'Intermediate Care, Subacute Inpatient - Reopening Claim' },
    { value: '671', text: 'Intermediate Care, Subacute Inpatient, Admit thru Discharge Claim' },
    {
        value: '67F',
        text: 'Intermediate Care, Subacute Inpatient, Beneficiary Initiated Adjustment Claim'
    },
    { value: '67G', text: 'Intermediate Care, Subacute Inpatient, CWF Initiated Adjustment Claim' },
    { value: '67H', text: 'Intermediate Care, Subacute Inpatient, HCFA Initiated Adjustment Claim' },
    { value: '67J', text: 'Intermediate Care, Subacute Inpatient, Initiated Adjustment Claim' },
    { value: '673', text: 'Intermediate Care, Subacute Inpatient, Interim - Continuing Claim' },
    { value: '672', text: 'Intermediate Care, Subacute Inpatient, Interim - First Claim' },
    { value: '674', text: 'Intermediate Care, Subacute Inpatient, Interim - Last Claim' },
    { value: '67I', text: 'Intermediate Care, Subacute Inpatient, Intermediary Adjustment Claim' },
    { value: '675', text: 'Intermediate Care, Subacute Inpatient, Late Charge(s) Only Claim' },
    { value: '67M', text: 'Intermediate Care, Subacute Inpatient, MSP Initiated Adjustment Claim' },
    {
        value: '67Z',
        text: 'Intermediate Care, Subacute Inpatient, New Abbreviated Encounter Submission'
    },
    { value: '670', text: 'Intermediate Care, Subacute Inpatient, Non-Payment/Zero Claim' },
    { value: '67O', text: 'Intermediate Care, Subacute Inpatient, Non-payment/Zero Claims' },
    { value: '67K', text: 'Intermediate Care, Subacute Inpatient, OIG Initiated Adjustment Claim' },
    { value: '67N', text: 'Intermediate Care, Subacute Inpatient, PRO Adjustment Claim' },
    {
        value: '67Y',
        text: 'Intermediate Care, Subacute Inpatient, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '677', text: 'Intermediate Care, Subacute Inpatient, Replacement of Prior Claim' },
    {
        value: '67X',
        text: 'Intermediate Care, Subacute Inpatient, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '678', text: 'Intermediate Care, Subacute Inpatient, Void/Cancel of Prior Claim' },
    { value: '78Q', text: 'Licensed Freestanding Emergency Medical Facility, Other - Reopening Claim' },
    {
        value: '781',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Admit thru Discharge Claim'
    },
    {
        value: '78F',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '78G',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, CWF Initiated Adjustment Claim'
    },
    {
        value: '78H',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, HCFA Initiated Adjustment Claim'
    },
    {
        value: '783',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Interim - Continuing Claim'
    },
    {
        value: '782',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Interim - First Claim'
    },
    {
        value: '784',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Interim - Last Claim'
    },
    {
        value: '785',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Late Charge(s) Only Claim'
    },
    {
        value: '78M',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, MSP Initiated Adjustment Claim'
    },
    {
        value: '78Z',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, New Abbreviated Encounter Submission'
    },
    {
        value: '780',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Non-Payment/Zero Claim'
    },
    {
        value: '78O',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Non-payment/Zero Claims'
    },
    {
        value: '78K',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, OIG Initiated Adjustment Claim'
    },
    {
        value: '78N',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, PRO Adjustment Claim'
    },
    {
        value: '78Y',
        text:
            'Licensed Freestanding Emergency Medical Facility, Other, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '787',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Replacement of Prior Claim'
    },
    {
        value: '78X',
        text:
            'Licensed Freestanding Emergency Medical Facility, Other, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '788',
        text: 'Licensed Freestanding Emergency Medical Facility, Other, Void/Cancel of Prior Claim'
    },
    { value: '8XA', text: 'Notice of Election (NOE)' },
    { value: '8XB', text: 'Notice of Termination/Revocation (NOTR)' },
    {
        value: '51Q',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A) - Reopening Claim'
    },
    {
        value: '51O',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A), Beneficiary-Initiated Adjustment Claim'
    },
    {
        value: '51E',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A), Hospice Change of Ownership'
    },
    {
        value: '51C',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A), Hospice Change of Provider Notice'
    },
    {
        value: '51B',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '51D',
        text:
            'Religious non-medical extended care institutions - inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    {
        value: '41Q',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A) - Reopening Claim'
    },
    {
        value: '41A',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Admission/Election Notice'
    },
    {
        value: '411',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Admit thru Discharge Claim'
    },
    {
        value: '41F',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '41G',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), CWF Initiated Adjustment Claim'
    },
    {
        value: '41H',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), HCFA Initiated Adjustment Claim'
    },
    {
        value: '41E',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-Medical Health Care Institution - Hospice Change of Ownership'
    },
    {
        value: '41C',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-Medical Health Care Institution - Hospice Change of Provider Notice'
    },
    {
        value: '41B',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-Medical Health Care Institution - Termination/Revocation Notice'
    },
    {
        value: '41D',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Hospice/Medicare Coordinated Care Demonstration/Religious Non-Medical Health Care Institution - Void/Cancel'
    },
    {
        value: '41J',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Initiated Adjustment Claim'
    },
    {
        value: '413',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Interim - Continuing Claim'
    },
    {
        value: '412',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Interim - First Claim'
    },
    {
        value: '414',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Interim - Last Claim'
    },
    {
        value: '41I',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Intermediary Adjustment Claim'
    },
    {
        value: '415',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Late Charge(s) Only Claim'
    },
    {
        value: '41M',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), MSP Initiated Adjustment Claim'
    },
    {
        value: '41Z',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), New Abbreviated Encounter Submission'
    },
    {
        value: '410',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Non-Payment/Zero Claim'
    },
    {
        value: '41O',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Non-payment/Zero Claims'
    },
    {
        value: '41K',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), OIG Initiated Adjustment Claim'
    },
    {
        value: '41N',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), PRO Adjustment Claim'
    },
    {
        value: '41Y',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '417',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Replacement of Prior Claim'
    },
    {
        value: '41X',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '418',
        text:
            'Religious non-medical health care institutions - hospital inpatient (including Medicare Part A), Void/Cancel of Prior Claim'
    },
    {
        value: '43Q',
        text: 'Religious non-medical health care institutions - hospital intermediate care - Level II - Reopening Claim'
    },
    {
        value: '43A',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Admission/Election Notice'
    },
    {
        value: '431',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Admit Through Discharge Claim'
    },
    {
        value: '43F',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Beneficiary-Initiated Adjustment Claim'
    },
    {
        value: '43H',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, CMS- Initiated Adjustment Claim'
    },
    {
        value: '43G',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, CWF- Initiated Adjustment Claim'
    },
    {
        value: '43I',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, FI Adjustment Claim (Other than QIO or Provider)'
    },
    {
        value: '43E',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Hospice Change of Ownership'
    },
    {
        value: '43C',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Hospice Change of Provider Notice'
    },
    {
        value: '43B',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '43D',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    {
        value: '43J',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Initiated Adjustment Claim - Other'
    },
    {
        value: '433',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Interim- Continuing Claims (Not valid for PPS Bills)'
    },
    {
        value: '432',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Interim- First Claim'
    },
    {
        value: '434',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Interim- Last Claim (Not valid for PPS Bills)'
    },
    {
        value: '435',
        text: 'Religious non-medical health care institutions - hospital intermediate care - Level II, Late Charge Only'
    },
    {
        value: '43M',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, MSP- Initiated Adjustment Claim'
    },
    {
        value: '43Z',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, New Abbreviated Encounter Submission'
    },
    {
        value: '430',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Non- payment/zero claims'
    },
    {
        value: '43K',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, OIG- Initiated Adjustment Claim'
    },
    {
        value: '43Y',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '437',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Replacement of Prior Claim'
    },
    {
        value: '43X',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '438',
        text:
            'Religious non-medical health care institutions - hospital intermediate care - Level II, Void/Cancel of a Prior Claim'
    },
    { value: '261', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '262', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '263', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '264', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '265', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '266', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '267', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '268', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '269', text: 'Skilled Nursing - Intermediate Care Level II (Level B)' },
    { value: '26Q', text: 'Skilled Nursing - Intermediate Care Level II (Level B) - Reopening Claim' },
    {
        value: '279',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility final claim for a home'
    },
    {
        value: '273',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility interim - continuing claims'
    },
    {
        value: '272',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility interim - first claim used for the...'
    },
    {
        value: '272',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility interim - first claim used for the...'
    },
    {
        value: '274',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility interim - last claim'
    },
    {
        value: '275',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility late charge only'
    },
    {
        value: '277',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility replacement of prior claim'
    },
    {
        value: '278',
        text: 'Skilled Nursing Intermediate Care - Level III Nursing Facility void/cancel of a prior claim'
    },
    {
        value: '271',
        text: 'Skilled Nursing Intermediate Care Level III-Nursing Facility Admit Thru Discharge'
    },
    { value: '251', text: 'Skilled Nursing Nursing Facility Level I Admit Through Discharge' },
    { value: '259', text: 'Skilled Nursing Nursing Facility Level I Final Claim for a Home' },
    { value: '253', text: 'Skilled Nursing Nursing Facility Level I Interim-Continuing Claims' },
    { value: '252', text: 'Skilled Nursing Nursing Facility Level I Interim-First Claim Used for the' },
    { value: '254', text: 'Skilled Nursing Nursing Facility Level I Interim-Last Claim' },
    { value: '255', text: 'Skilled Nursing Nursing Facility Level I Late Charge Only' },
    { value: '257', text: 'Skilled Nursing Nursing Facility Level I Replacement of Prior Claim' },
    { value: '258', text: 'Skilled Nursing Nursing Facility Level I Void/Cancel of a Prior Claim' },
    { value: '21Q', text: 'Skilled Nursing, Inpatient (Including Medicare Part A) - Reopening Claim' },
    {
        value: '211',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Admit thru Discharge Claim'
    },
    {
        value: '21F',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '21G',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), CWF Initiated Adjustment Claim'
    },
    {
        value: '21H',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), HCFA Initiated Adjustment Claim'
    },
    {
        value: '21J',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Initiated Adjustment Claim - Other'
    },
    {
        value: '213',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Interim - Continuing Claim'
    },
    {
        value: '212',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Interim - First Claim'
    },
    {
        value: '214',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Interim - Last Claim'
    },
    {
        value: '21I',
        text:
            'Skilled Nursing, Inpatient (Including Medicare Part A), Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    {
        value: '215',
        text:
            'Skilled Nursing, Inpatient (Including Medicare Part A), Late Charge(s) Only Claim (Used for inpatient Part A bill for inpatient ancillary services)'
    },
    {
        value: '21M',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), MSP Initiated Adjustment Claim'
    },
    {
        value: '21Z',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), New Abbreviated Encounter Submission'
    },
    {
        value: '210',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Non-Payment/Zero Claim'
    },
    {
        value: '21O',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Non-payment/Zero Claims'
    },
    {
        value: '21K',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), OIG Initiated Adjustment Claim'
    },
    {
        value: '21N',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), PRO Adjustment Claim'
    },
    {
        value: '21Y',
        text:
            'Skilled Nursing, Inpatient (Including Medicare Part A), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '217',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Replacement of Prior Claim'
    },
    {
        value: '21X',
        text:
            'Skilled Nursing, Inpatient (Including Medicare Part A), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '218',
        text: 'Skilled Nursing, Inpatient (Including Medicare Part A), Void/Cancel of a Prior Claim'
    },
    { value: '22Q', text: 'Skilled Nursing, Inpatient (Medicare Part B only) - Reopening Claim' },
    {
        value: '221',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Admit thru Discharge Claim'
    },
    {
        value: '22F',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '22G',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), CWF Initiated Adjustment Claim'
    },
    {
        value: '22H',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), HCFA Initiated Adjustment Claim'
    },
    {
        value: '22J',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Initiated Adjustment Claim - Other'
    },
    {
        value: '223',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Interim - Continuing Claim'
    },
    { value: '222', text: 'Skilled Nursing, Inpatient (Medicare Part B only), Interim - First Claim' },
    { value: '224', text: 'Skilled Nursing, Inpatient (Medicare Part B only), Interim - Last Claim' },
    {
        value: '22I',
        text:
            'Skilled Nursing, Inpatient (Medicare Part B only), Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    {
        value: '225',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Late Charge(s) Only Claim'
    },
    {
        value: '22M',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), MSP Initiated Adjustment Claim'
    },
    {
        value: '22Z',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), New Abbreviated Encounter Submission'
    },
    { value: '220', text: 'Skilled Nursing, Inpatient (Medicare Part B only), Non-Payment/Zero Claim' },
    {
        value: '22O',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Non-payment/Zero Claims'
    },
    {
        value: '22K',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), OIG Initiated Adjustment Claim'
    },
    { value: '22N', text: 'Skilled Nursing, Inpatient (Medicare Part B only), PRO Adjustment Claim' },
    {
        value: '22Y',
        text:
            'Skilled Nursing, Inpatient (Medicare Part B only), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '227',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Replacement of Prior Claim'
    },
    {
        value: '22X',
        text:
            'Skilled Nursing, Inpatient (Medicare Part B only), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '228',
        text: 'Skilled Nursing, Inpatient (Medicare Part B only), Void/Cancel of Prior Claim'
    },
    {
        value: '241',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Admit thru Discharge Claim'
    },
    {
        value: '24F',
        text:
            'Skilled Nursing, Other (for hospital referenced diagnostic services), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '24G',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), CWF Initiated Adjustment Claim'
    },
    {
        value: '24H',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), HCFA Initiated Adjustment Claim'
    },
    {
        value: '24J',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Initiated Adjustment Claim - Other'
    },
    {
        value: '243',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Interim - Continuing Claim'
    },
    {
        value: '242',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Interim - First Claim'
    },
    {
        value: '244',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Interim - Last Claim'
    },
    {
        value: '24I',
        text:
            'Skilled Nursing, Other (for hospital referenced diagnostic services), Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    {
        value: '245',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Late Charge(s) Only Claim'
    },
    {
        value: '24M',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), MSP Initiated Adjustment Claim'
    },
    {
        value: '24Z',
        text:
            'Skilled Nursing, Other (for hospital referenced diagnostic services), New Abbreviated Encounter Submission'
    },
    {
        value: '24O',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Non-payment/Zero Claims'
    },
    {
        value: '240',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Non-Payment/Zero Claim'
    },
    {
        value: '24K',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), OIG Initiated Adjustment Claim'
    },
    {
        value: '24N',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), PRO Adjustment Claim'
    },
    {
        value: '24Y',
        text:
            'Skilled Nursing, Other (for hospital referenced diagnostic services), Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '247',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Replacement of Prior Claim'
    },
    {
        value: '24X',
        text:
            'Skilled Nursing, Other (for hospital referenced diagnostic services), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '248',
        text: 'Skilled Nursing, Other (for hospital referenced diagnostic services), Void/Cancel of Prior Claim'
    },
    { value: '24Q', text: 'Skilled Nursing, Other - Reopening Claim' },
    { value: '23Q', text: 'Skilled Nursing, Outpatient - Reopening Claim' },
    { value: '231', text: 'Skilled Nursing, Outpatient, Admit thru Discharge Claim' },
    { value: '23F', text: 'Skilled Nursing, Outpatient, Beneficiary Initiated Adjustment Claim' },
    { value: '23G', text: 'Skilled Nursing, Outpatient, CWF Initiated Adjustment Claim' },
    { value: '23H', text: 'Skilled Nursing, Outpatient, HCFA Initiated Adjustment Claim' },
    { value: '23J', text: 'Skilled Nursing, Outpatient, Initiated Adjustment Claim - Other' },
    { value: '233', text: 'Skilled Nursing, Outpatient, Interim - Continuing Claim' },
    { value: '232', text: 'Skilled Nursing, Outpatient, Interim - First Claim' },
    { value: '234', text: 'Skilled Nursing, Outpatient, Interim - Last Claim' },
    {
        value: '23I',
        text: 'Skilled Nursing, Outpatient, Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    { value: '235', text: 'Skilled Nursing, Outpatient, Late Charge(s) Only Claim' },
    { value: '23M', text: 'Skilled Nursing, Outpatient, MSP Initiated Adjustment Claim' },
    { value: '23Z', text: 'Skilled Nursing, Outpatient, New Abbreviated Encounter Submission' },
    { value: '230', text: 'Skilled Nursing, Outpatient, Non-Payment/Zero Claim' },
    { value: '23O', text: 'Skilled Nursing, Outpatient, Non-payment/Zero Claims' },
    { value: '23K', text: 'Skilled Nursing, Outpatient, OIG Initiated Adjustment Claim' },
    { value: '23N', text: 'Skilled Nursing, Outpatient, PRO Adjustment Claim' },
    {
        value: '23Y',
        text: 'Skilled Nursing, Outpatient, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '237', text: 'Skilled Nursing, Outpatient, Replacement of Prior Claim' },
    {
        value: '23X',
        text: 'Skilled Nursing, Outpatient, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '238', text: 'Skilled Nursing, Outpatient, Void/Cancel of Prior Claim' },
    { value: '28Q', text: 'Skilled Nursing, Swing Beds - Reopening Claim' },
    { value: '281', text: 'Skilled Nursing, Swing Beds, Admit thru Discharge Claim' },
    { value: '28F', text: 'Skilled Nursing, Swing Beds, Beneficiary Initiated Adjustment Claim' },
    { value: '28G', text: 'Skilled Nursing, Swing Beds, CWF Initiated Adjustment Claim' },
    { value: '289', text: 'Skilled Nursing, Swing Beds, Final Claim for a Home Health PPS Episode' },
    { value: '28H', text: 'Skilled Nursing, Swing Beds, HCFA Initiated Adjustment Claim' },
    { value: '28J', text: 'Skilled Nursing, Swing Beds, Initiated Adjustment Claim - Other' },
    { value: '283', text: 'Skilled Nursing, Swing Beds, Interim - Continuing Claim' },
    { value: '282', text: 'Skilled Nursing, Swing Beds, Interim - First Claim' },
    { value: '284', text: 'Skilled Nursing, Swing Beds, Interim - Last Claim' },
    {
        value: '28I',
        text: 'Skilled Nursing, Swing Beds, Intermediary Adjustment Claim (Other Than Pro or Provider)'
    },
    { value: '285', text: 'Skilled Nursing, Swing Beds, Late Charge(s) Only Claim' },
    { value: '28M', text: 'Skilled Nursing, Swing Beds, MSP Initiated Adjustment Claim' },
    { value: '28Z', text: 'Skilled Nursing, Swing Beds, New Abbreviated Encounter Submission' },
    { value: '280', text: 'Skilled Nursing, Swing Beds, Non-Payment/Zero Claim' },
    { value: '28O', text: 'Skilled Nursing, Swing Beds, Non-payment/Zero Claims' },
    { value: '28K', text: 'Skilled Nursing, Swing Beds, OIG Initiated Adjustment Claim' },
    { value: '28N', text: 'Skilled Nursing, Swing Beds, PRO Adjustment Claim' },
    {
        value: '28Y',
        text: 'Skilled Nursing, Swing Beds, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '287', text: 'Skilled Nursing, Swing Beds, Replacement of Prior Claim' },
    {
        value: '28X',
        text: 'Skilled Nursing, Swing Beds, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '288', text: 'Skilled Nursing, Swing Beds, Void/Cancel of Prior Claim' },
    { value: '83Q', text: 'Special Facility, Ambulatory Surgery Center - Reopening Claim' },
    { value: '831', text: 'Special Facility, Ambulatory Surgery Center, Admit thru Discharge Claim' },
    {
        value: '83F',
        text: 'Special Facility, Ambulatory Surgery Center, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '83G',
        text: 'Special Facility, Ambulatory Surgery Center, CWF Initiated Adjustment Claim'
    },
    {
        value: '83H',
        text: 'Special Facility, Ambulatory Surgery Center, HCFA Initiated Adjustment Claim'
    },
    { value: '83J', text: 'Special Facility, Ambulatory Surgery Center, Initiated Adjustment Claim' },
    { value: '833', text: 'Special Facility, Ambulatory Surgery Center, Interim - Continuing Claim' },
    { value: '832', text: 'Special Facility, Ambulatory Surgery Center, Interim - First Claim' },
    { value: '834', text: 'Special Facility, Ambulatory Surgery Center, Interim - Last Claim' },
    {
        value: '83I',
        text: 'Special Facility, Ambulatory Surgery Center, Intermediary Adjustment Claim'
    },
    { value: '835', text: 'Special Facility, Ambulatory Surgery Center, Late Charge(s) Only Claim' },
    {
        value: '83M',
        text: 'Special Facility, Ambulatory Surgery Center, MSP Initiated Adjustment Claim'
    },
    {
        value: '83Z',
        text: 'Special Facility, Ambulatory Surgery Center, New Abbreviated Encounter Submission'
    },
    { value: '830', text: 'Special Facility, Ambulatory Surgery Center, Non-Payment/Zero Claim' },
    { value: '83O', text: 'Special Facility, Ambulatory Surgery Center, Non-payment/Zero Claims' },
    {
        value: '83K',
        text: 'Special Facility, Ambulatory Surgery Center, OIG Initiated Adjustment Claim'
    },
    { value: '83N', text: 'Special Facility, Ambulatory Surgery Center, PRO Adjustment Claim' },
    {
        value: '83Y',
        text: 'Special Facility, Ambulatory Surgery Center, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '837', text: 'Special Facility, Ambulatory Surgery Center, Replacement of Prior Claim' },
    {
        value: '83X',
        text: 'Special Facility, Ambulatory Surgery Center, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '838', text: 'Special Facility, Ambulatory Surgery Center, Void/Cancel of Prior Claim' },
    { value: '85Q', text: 'Special Facility, Critical Access Hospital - Reopening Claim' },
    { value: '851', text: 'Special Facility, Critical Access Hospital, Admit thru Discharge Claim' },
    {
        value: '85F',
        text: 'Special Facility, Critical Access Hospital, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '85G',
        text: 'Special Facility, Critical Access Hospital, CWF Initiated Adjustment Claim'
    },
    {
        value: '85H',
        text: 'Special Facility, Critical Access Hospital, HCFA Initiated Adjustment Claim'
    },
    {
        value: '85J',
        text: 'Special Facility, Critical Access Hospital, Initiated Adjustment Claim Other'
    },
    { value: '853', text: 'Special Facility, Critical Access Hospital, Interim - Continuing Claim' },
    { value: '852', text: 'Special Facility, Critical Access Hospital, Interim - First Claim' },
    { value: '854', text: 'Special Facility, Critical Access Hospital, Interim - Last Claim' },
    { value: '85I', text: 'Special Facility, Critical Access Hospital, Intermediary Adjustment Claim' },
    { value: '855', text: 'Special Facility, Critical Access Hospital, Late Charge(s) Only Claim' },
    {
        value: '85M',
        text: 'Special Facility, Critical Access Hospital, MSP Initiated Adjustment Claim'
    },
    {
        value: '85Z',
        text: 'Special Facility, Critical Access Hospital, New Abbreviated Encounter Submission'
    },
    { value: '850', text: 'Special Facility, Critical Access Hospital, Non-Payment/Zero Claim' },
    { value: '85O', text: 'Special Facility, Critical Access Hospital, Non-payment/Zero Claims' },
    {
        value: '85K',
        text: 'Special Facility, Critical Access Hospital, OIG Initiated Adjustment Claim'
    },
    { value: '85N', text: 'Special Facility, Critical Access Hospital, PRO Adjustment Claim' },
    {
        value: '85Y',
        text: 'Special Facility, Critical Access Hospital, Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '857', text: 'Special Facility, Critical Access Hospital, Replacement of Prior Claim' },
    {
        value: '85X',
        text: 'Special Facility, Critical Access Hospital, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '858', text: 'Special Facility, Critical Access Hospital, Void/Cancel of Prior Claim' },
    { value: '84Q', text: 'Special Facility, Free Standing Birthing Center - Reopening Claim' },
    {
        value: '841',
        text: 'Special Facility, Free Standing Birthing Center, Admit thru Discharge Claim'
    },
    {
        value: '84F',
        text: 'Special Facility, Free Standing Birthing Center, Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '84G',
        text: 'Special Facility, Free Standing Birthing Center, CWF Initiated Adjustment Claim'
    },
    {
        value: '84H',
        text: 'Special Facility, Free Standing Birthing Center, HCFA Initiated Adjustment Claim'
    },
    {
        value: '84J',
        text: 'Special Facility, Free Standing Birthing Center, Initiated Adjustment Claim'
    },
    {
        value: '843',
        text: 'Special Facility, Free Standing Birthing Center, Interim - Continuing Claim'
    },
    { value: '842', text: 'Special Facility, Free Standing Birthing Center, Interim - First Claim' },
    { value: '844', text: 'Special Facility, Free Standing Birthing Center, Interim - Last Claim' },
    {
        value: '84I',
        text: 'Special Facility, Free Standing Birthing Center, Intermediary Adjustment Claim'
    },
    {
        value: '845',
        text: 'Special Facility, Free Standing Birthing Center, Late Charge(s) Only Claim'
    },
    {
        value: '84M',
        text: 'Special Facility, Free Standing Birthing Center, MSP Initiated Adjustment Claim'
    },
    {
        value: '84Z',
        text: 'Special Facility, Free Standing Birthing Center, New Abbreviated Encounter Submission'
    },
    { value: '840', text: 'Special Facility, Free Standing Birthing Center, Non-Payment/Zero Claim' },
    { value: '84O', text: 'Special Facility, Free Standing Birthing Center, Non-payment/Zero Claims' },
    {
        value: '84K',
        text: 'Special Facility, Free Standing Birthing Center, OIG Initiated Adjustment Claim'
    },
    { value: '84N', text: 'Special Facility, Free Standing Birthing Center, PRO Adjustment Claim' },
    {
        value: '84Y',
        text: 'Special Facility, Free Standing Birthing Center, Replacement of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '847',
        text: 'Special Facility, Free Standing Birthing Center, Replacement of Prior Claim'
    },
    {
        value: '84X',
        text: 'Special Facility, Free Standing Birthing Center, Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    {
        value: '848',
        text: 'Special Facility, Free Standing Birthing Center, Void/Cancel of Prior Claim'
    },
    { value: '82Q', text: 'Special Facility, Hospice (hospital-based) - Reopening Claim' },
    { value: '82A', text: 'Special Facility, Hospice (hospital-based), Admission/Election Notice' },
    { value: '821', text: 'Special Facility, Hospice (hospital-based), Admit thru Discharge Claim' },
    {
        value: '82F',
        text: 'Special Facility, Hospice (hospital-based), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '82G',
        text: 'Special Facility, Hospice (hospital-based), CWF Initiated Adjustment Claim'
    },
    {
        value: '829',
        text: 'Special Facility, Hospice (hospital-based), Final Claim for a Home Health PPS Episode'
    },
    {
        value: '82H',
        text: 'Special Facility, Hospice (hospital-based), HCFA Initiated Adjustment Claim'
    },
    { value: '82E', text: 'Special Facility, Hospice (hospital-based), Hospice Change of Ownership' },
    {
        value: '82C',
        text: 'Special Facility, Hospice (hospital-based), Hospice Change of Provider Notice'
    },
    {
        value: '82B',
        text:
            'Special Facility, Hospice (hospital-based), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Termination/Revocation Notice'
    },
    {
        value: '82D',
        text:
            'Special Facility, Hospice (hospital-based), Hospice/Medicare Coordinated Care Demonstration/Religious Non-medical Health Care Institution-Void/Cancel'
    },
    {
        value: '82J',
        text: 'Special Facility, Hospice (hospital-based), Initiated Adjustment Claim Other'
    },
    { value: '823', text: 'Special Facility, Hospice (hospital-based), Interim - Continuing Claim' },
    { value: '822', text: 'Special Facility, Hospice (hospital-based), Interim - First Claim' },
    { value: '824', text: 'Special Facility, Hospice (hospital-based), Interim - Last Claim' },
    { value: '82I', text: 'Special Facility, Hospice (hospital-based), Intermediary Adjustment Claim' },
    { value: '825', text: 'Special Facility, Hospice (hospital-based), Late Charge(s) Only Claim' },
    {
        value: '82M',
        text: 'Special Facility, Hospice (hospital-based), MSP Initiated Adjustment Claim'
    },
    {
        value: '82Z',
        text: 'Special Facility, Hospice (hospital-based), New Abbreviated Encounter Submission'
    },
    { value: '820', text: 'Special Facility, Hospice (hospital-based), Non-Payment/Zero Claim' },
    { value: '82O', text: 'Special Facility, Hospice (hospital-based), Non-payment/Zero Claims' },
    {
        value: '82K',
        text: 'Special Facility, Hospice (hospital-based), OIG Initiated Adjustment Claim'
    },
    { value: '82N', text: 'Special Facility, Hospice (hospital-based), PRO Adjustment Claim' },
    {
        value: '82Y',
        text: 'Special Facility, Hospice (hospital-based), Replacement of a Prior Abbreviated Encounter Submission'
    },
    { value: '827', text: 'Special Facility, Hospice (hospital-based), Replacement of Prior Claim' },
    {
        value: '82X',
        text: 'Special Facility, Hospice (hospital-based), Void/Cancel of a Prior Abbreviated Encounter Submission'
    },
    { value: '828', text: 'Special Facility, Hospice (hospital-based), Void/Cancel of Prior Claim' },
    { value: '81Q', text: 'Special Facility, Hospice (non-hospital based) - Reopening Claim' },
    { value: '81A', text: 'Special Facility, Hospice (non-hospital based), Admission/Election Notice' },
    {
        value: '811',
        text: 'Special Facility, Hospice (non-hospital based), Admit thru Discharge Claim'
    },
    {
        value: '81F',
        text: 'Special Facility, Hospice (non-hospital based), Beneficiary Initiated Adjustment Claim'
    },
    {
        value: '81G',
        text: 'Special Facility, Hospice (non-hospital based), CWF Initiated Adjustment Claim'
    },
    {
        value: '819',
        text: 'Special Facility, Hospice (non-hospital based), Final Claim for a Home Health PPS Episode'
    },
    {
        value: '81H',
        text: 'Special Facility, Hospice (non-hospital based), HCFA Initiated Adjustment Claim'
    },
    {
        value: '81E',
        text: 'Special Facility, Hospice (non-hospital based), Hospice Change of Ownership'
    }
];

export const AdmissionTypeOptions = [
    { value: '3', text: 'Elective' },
    { value: '1', text: 'Emergency' },
    { value: '9', text: 'Information Not Available' },
    { value: '4', text: 'Newborn' },
    { value: '5', text: 'Trauma' },
    { value: '2', text: 'Urgent' }
];

export const AdmissionSourceOptions = [
    { value: '2', text: 'Clinic or Physicians Office' },
    { value: '8', text: 'Court/Law Enforcement' },
    {
        value: 'G ',
        text: 'Effective 7-1-2020 Transfer from Designated Disaster Alternative Care Sites (ACS)'
    },
    { value: '9', text: 'Information Not Available' },
    { value: '5', text: 'Newborn-Born Inside This Hospital' },
    { value: '6', text: 'Newborn-Born Outside This Hospital' },
    { value: '1', text: 'Non-healthcare Facility Point of Origin' },
    { value: '4', text: 'Transfer From a Hospital (Different Facility)' },
    {
        value: '5',
        text: 'Transfer From a Skilled Nursing or Intermediate Care Facility / Born Inside This Hospital'
    },
    { value: 'E ', text: 'Transfer from Ambulatory Surgery Center' },
    { value: '6', text: 'Transfer From Another Healthcare Facility / Born Outside of This Hospital' },
    {
        value: 'F ',
        text: 'Transfer From Hospice and is Under Plan of Care or Enrolled in a Hospice Program'
    },
    {
        value: 'D ',
        text:
            'Transfer From One Distinct Unit of the Hospital to Another Distinct Unit of Same Hospital Resulting in a Separate Claim to the Payer'
    }
];

export const PatientStatusOptions = [
    { value: '01', text: '01 Discharged to home or self care (routine discharge)' },
    { value: '02',
        text: '02 Discharged/transferred to another short term general hospital for inpatient care'
    },
    {
        value: '03',
        text: '03 Discharged/transferred to SNF with medicare certification in anticipation of skilled care'
    },
    { value: '04', text: '04 Discharged/transferred to an intermediate care facility (ICF)' },
    { value: '05', text: '05 Discharged/transferred to a designated cancer center or childrens hospital' },
    { value: '06', text:
            '06 Discharged/transferred to home under care of organized home health service organization in anticipation of covered skilled care'
    },
    { value: '07', text: '07 Left against medical advice or discontinued care' },
    { value: '09', text: '09 Admitted as an inpatient to this hospital' },
    { value: '21', text: '21 Discharged to court or law enforcement' },
    { value: '43', text: '43 Discharged/transferred to a federal hospital' },
    { value: '50', text: '50 Discharged to hospice-home' },
    { value: '51', text: '51 Discharged to hospice-medical facility' },
    { value: '69', text: '69 Discharged/Transferred to a designated disaster alternate care' },
    { value: '81', text: '81 Discharged to Home or Self Care with a Planned Acute. Care Hospital Inpatient Readmission'},
    { value: '84',
        text:
            '84 Discharged/Transferred to a Facility that Provides Custodial or Supportive Care with a Planned Acute Care Hospital Inpatient Readmission'
    },
    { value: '85', text: '85 Discharged/transferred to a Designated Cancer Center or Childrens Hospital with a Planned Acute Care Hospital Inpatient Readmission' },
    { value: '94', text: '94 Discharged/Transferred To a Critical Access Hospital (CAR) with a Planned Acute Care Hospital Inpatient Readmission'},

    { value: '88',
        text:
            '88 Discharged/Transferred to a Federal Health Care Facility with a Planned Acute Care Hospital Inpatient Readmission'
    },

    { value: '61', text: '61 Discharged/transferred to a hospital based medicare approved swing bed' },
    {
        value: '89',
        text:
            'Discharged/Transferred to a Hospital-based Medicare Approved Swing Bed with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '91',
        text:
            'Discharged/Transferred to a Medicare Certified Long Term Care Hospital (LTCH) with a Planned Acute Care Hospital Inpatient Readmission'
    },
    { value: '63', text: 'Discharged/transferred to a medicare certified long-term care hospital' },
    {
        value: '64',
        text: 'Discharged/transferred to a nursing facility certified under Medicaid but not certified under medicare'
    },
    {
        value: '92',
        text:
            'Discharged/Transferred to a Nursing Facility Certified Under Medicaid but not Certified Under Medicare with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '93',
        text:
            'Discharged/Transferred to a Psychiatric Hospital or Psychiatric Distinct Part Unit of a Hospital with a Planned Acute Care Hospital Inpatient Readmission'
    },

    {
        value: '82',
        text:
            'Discharged/Transferred to a Short Term General Hospital for Inpatient Care with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '83',
        text:
            'Discharged/Transferred to a Skilled Nursing Facility (SNF) with Medicare Certification with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '90',
        text:
            'Discharged/Transferred to an Inpatient Rehabilitation Facility (IRF) including Rehabilitation Distinct Part Units of a Hospital with a Planned Acute Care Hospital Inpatient 62 '
    },

    {
        value: '95',
        text:
            'Discharged/Transferred to Another Type of Health Care Institution not Defined Elsewhere in this Code List with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '70',
        text: 'Discharged/transferred to another type of healthcare institution not defined elsewhere in this code list'
    },
    {
        value: '87',
        text: 'Discharged/Transferred to Court/Law Enforcement with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '86',
        text:
            'Discharged/Transferred to Home under Care of Organized Home Health Service Organization with a Planned Acute Care Hospital Inpatient Readmission'
    },
    {
        value: '65',
        text: 'Discharged/transferred to psychiatric hospital or psychiatric distinct part unit of a hospital'
    },
    { value: '66', text: 'Discharged/transfers to a critical access hospital' },
    { value: '20', text: 'Expired' },
    { value: '40', text: 'Expired at home (for hospice care only)' },
    {
        value: '41',
        text:
            'Expired in a medical facility such as a hospital, snf, icf, or freestanding hospice (for hospice care only)'
    },
    { value: '42', text: 'Expired, place unknown (for hospice care only)' },
    { value: '30', text: 'Still a patient' }
];

export const ConditionCodesOptions = [
    {
        value: 'AE',
        text: 'Abortion performed due to a physical health of mother that is not life endangering'
    },
    { value: 'AF', text: 'Abortion performed due to a emotional/psychological health of mother' },
    { value: 'AD', text: 'Abortion performed due to a life endangering physical condition' },
    { value: 'AG', text: 'Abortion performed due to a social or economic reasons' },
    { value: 'AB', text: 'Abortion performed due to incest' },
    { value: 'AA', text: 'Abortion performed due to rape' },
    {
        value: 'AC',
        text: 'Abortion performed due to serious fetal genetic defect, deformity or abnormality'
    },
    {
        value: '86',
        text: 'Additional hemodialysis treatments with medical justification (Effective TBD)'
    },
    { value: 'B4', text: 'Admission unrelated to discharge on same day' },
    { value: 'C6', text: 'Admission/preauthorization' },
    { value: 'C4', text: 'Admission/services denied' },
    { value: 'AK', text: 'Air ambulance required' },
    { value: 'M0', text: 'All Inclusive Rate for Outpatient Services - Payer Use Only Code' },
    {
        value: 'M2',
        text: 'Allows Home Health claims to process if provider reimbursement > $150,000.00 - Payer Use Only Code'
    },
    { value: '45', text: 'Ambiguous gender category' },
    { value: 'D9', text: 'Any other change' },
    { value: 'C1', text: 'Approved as billed' },
    { value: '51', text: 'Attestation of unrelated outpatient non-diagnostic services' },
    { value: 'C2', text: 'Automatic approval as billed based on focused review' },
    { value: '76', text: 'Backup in facility dialysis' },
    { value: '68', text: 'Beneficiary elects to use lifetime reserve LTR' },
    { value: 'B1', text: 'Beneficiary ineligible for demonstration program' },
    { value: '52', text: 'Beneficiary moved out of service area' },
    { value: '20', text: 'Beneficiary requested billing' },
    {
        value: '8',
        text: 'Beneficiary would not provide information concerning other insurance coverage'
    },
    { value: '67', text: 'Beneificary elects not to use lifetime reserve LTR days' },
    { value: '21', text: 'Billing for denial notice' },
    { value: '83', text: 'C-sections or inductions performed at 39 weeks gestation or greater' },
    { value: '81', text: 'C-Sections/Inductions & < 39 weeks - Medical Necessity' },
    { value: 'D6', text: 'Cancel only to repay a duplicate or OIG overpayment' },
    { value: 'D5', text: 'Cancel to correct insured ID or provider ID' },
    { value: 'A0', text: 'Champus external partnership program' },
    { value: 'D4', text: 'Change in clinical codes (ICD) for diagnosis and/or procedure codes' },
    { value: 'E0', text: 'Change in patient status' },
    { value: 'D8', text: 'Change to make medicare the primary payer' },
    { value: 'D7', text: 'Change to make medicare the secondary payer' },
    { value: 'D2', text: 'Changes in revenue codes/hcpcs/HIPPS rate codes' },
    { value: 'D1', text: 'Changes to charges' },
    { value: 'D0', text: 'Changes to service dates' },
    { value: '19', text: 'Child retains mothers name' },
    { value: '15', text: 'Clean Claim is delayed in CMS Processing System - Payer Use Only Code' },
    { value: '2', text: 'Condition is employment related' },
    { value: 'XA', text: 'Condition stable' },
    { value: '43', text: 'Continuing care not provided within prescribed post discharge window' },
    { value: '42', text: 'Continuing care not related to inpatient admission' },
    { value: '79', text: 'CORF services provided off-site' },
    { value: '61', text: 'Cost outlier' },
    { value: 'B2', text: 'Critical access hospital ambulance attestation' },
    { value: '98', text: 'Data associated with DRG 468 has been validated - Payer Use Only Code' },
    { value: '60', text: 'Day outlier' },
    { value: 'H0', text: 'Delayed filing statement of intent submitted' },
    { value: '85', text: 'Delayed Recertification of Hospice Terminal Illness' },
    { value: '84', text: 'Dialysis for Acute Kidney Injury (AKI)' },
    { value: 'A5', text: 'Disability' },
    { value: '29', text: 'Disabled beneficiary and/or family members LGHP is secondary to medicare' },
    { value: '11', text: 'Disabled beneficiary, but no large group health plan (lghp) coverage' },
    { value: 'DR', text: 'Disaster related' },
    { value: 'H2', text: 'Discharge by a hospice provider for cause' },
    { value: 'G0', text: 'Distinct medical visit' },
    { value: 'P0', text: 'DNR - Do not resuscitate order For Public Health Reporting Only' },
    { value: 'P1', text: 'Do Not Resuscitate Order - DNR - For Public Health Reporting Only' },
    { value: 'W2', text: 'Duplicate of original bill' },
    { value: 'AH', text: 'Elective abortion' },
    { value: 'A1', text: 'EPSDT/CHAP' },
    { value: 'MA', text: 'ESRD Comorbid Condition, GI Bleed - Payer Use Only Code' },
    {
        value: 'ME',
        text: 'ESRD Comorbid Condition, Hereditary hemolytic and sickle cell anemia - Payer Use Only Code'
    },
    { value: 'MF', text: 'ESRD Comorbid Condition, Monoclonal gammopathy - Payer Use Only Code' },
    { value: 'MD', text: 'ESRD Comorbid Condition, Myelodysplastic Syndrome - Payer Use Only Code' },
    { value: 'MC', text: 'ESRD Comorbid Condition, Pericarditis - Payer Use Only Code' },
    { value: 'MB', text: 'ESRD Comorbid Condition, Pneumonia - Payer Use Only Code' },
    {
        value: '6',
        text: 'ESRD patient in first 30 months of entitlement covered by employer group health insurance'
    },
    { value: '87', text: 'ESRD Self Care Retraining' },
    { value: 'C7', text: 'Extended authorization' },
    { value: 'A4', text: 'Family planning' },
    { value: '71', text: 'Full care in unit' },
    { value: '36', text: 'General care patient in a special unit' },
    {
        value: 'MG',
        text: 'Grandfathered Tribal Federally Qualified Health Centers - Medicare Only Code'
    },
    { value: '74', text: 'Home' },
    { value: '23', text: 'Home care giver available' },
    { value: '80', text: 'Home dialysis nursing facility' },
    { value: '24', text: 'Home iv patient also receiving HHA services' },
    { value: '75', text: 'Home-100 percent reimbursement' },
    { value: 'A7', text: 'Hospital Services provided in a Mobile Facility or with Portable Units' },
    { value: '69', text: 'IME/DGME/N&AH payment only' },
    { value: '63', text: 'Incarcerated beneficiaries' },
    { value: '4', text: 'Information only bill' },
    {
        value: '53',
        text: 'Initial placement of a medical device provided as part of a clinical trial or free sample'
    },
    { value: '44', text: 'Inpatient admission changed to outpatient' },
    { value: 'W3', text: 'Level I appeal' },
    { value: 'W4', text: 'Level II appeal' },
    { value: 'W5', text: 'Level III appeal' },
    { value: '5', text: 'Lien has been field' },
    {
        value: 'EY',
        text: 'Lung reduction study demonstration claims - Not assigned by the NUBC - Payer Use Only Code'
    },
    { value: '18', text: 'Maiden name retained' },
    { value: 'XC', text: 'Maintenance care' },
    { value: '56', text: 'Medical appropriateness' },
    { value: 'B0', text: 'Medicare coordinated care demonstration claim' },
    { value: 'X4', text: 'Medicare Denial on File' },
    { value: 'X2', text: 'Medicare EOMB on File' },
    { value: '1', text: 'Military service related' },
    { value: '9', text: 'Neither patient nor spouse is employed' },
    { value: '78', text: 'New coverage not implemented by managed care plan' },
    {
        value: '54',
        text: 'No skilled home health visits in billing period. Policy exception documented at the Home Health Agency'
    },
    { value: 'XD', text: 'No skilled service' },
    { value: 'Y8', text: 'Non covered services' },
    { value: 'AM', text: 'Non emergency medically necessary stretcher transport required' },
    { value: '59', text: 'Non-primary ESRD facility' },
    { value: '46', text: 'Nonavailability statement on file' },
    {
        value: '30',
        text: 'Nonresearch services provided to patients enrolled in a qualified clinical trial'
    },
    { value: 'XB', text: 'Not homebound' },
    { value: 'NU', text: 'Not Used' },
    { value: '89', text: 'OPIOID TREATMENT PROGRAM (EFFECTIVE 1/1/21)' },
    { value: 'C3', text: 'Partial approval' },
    { value: '41', text: 'Partial hospitalization' },
    {
        value: 'P7',
        text:
            'Patient admitted directly from this facilities emergency room/department For Public Health Reporting Only'
    },
    { value: '10', text: 'Patient and/or spouse is employed but no EGHP coverage exists' },
    { value: '28', text: 'Patient and/or spouses EGHP is secondary to Medicare' },
    { value: '3', text: 'Patient covered by insurance not reflected here' },
    { value: '25', text: 'Patient is a non-U.S. resident' },
    { value: '17', text: 'Patient is homeless' },
    { value: '32', text: 'Patient is student cooperative work study program' },
    { value: '31', text: 'Patient is student full time day' },
    { value: '33', text: 'Patient is student full time night' },
    { value: '34', text: 'Patient is student part time' },
    { value: '82', text: 'Patient neither giving birth or was born' },
    { value: '22', text: 'Patient on multiple drug regimen' },
    {
        value: '27',
        text: 'Patient referred to a sole community hospital for a diagnostic laboratory test'
    },
    { value: '62', text: 'Payer code' },
    { value: '64', text: 'Payer only code' },
    { value: '65', text: 'Payer only code' },
    { value: 'AJ', text: 'Payer responsible for copayment' },
    { value: '12', text: 'Payer Use Only Code' },
    { value: '13', text: 'Payer Use Only Code' },
    { value: '14', text: 'Payer Use Only Code' },
    { value: 'M4', text: 'Payer Use only Code Used by Medicare' },
    { value: 'M5', text: 'Payer Use only Code Used by Medicare' },
    { value: 'M6', text: 'Payer Use only Code Used by Medicare' },
    { value: 'M7', text: 'Payer Use only Code Used by Medicare' },
    { value: 'M8', text: 'Payer Use only Code Used by Medicare' },
    { value: 'M9', text: 'Payer Use only Code Used by Medicare' },
    { value: 'A2', text: 'Physically handicapped childrens program' },
    { value: 'C5', text: 'Postpayment review applicable' },
    { value: 'AN', text: 'Preadmission screening not required' },
    { value: 'B3', text: 'Pregnancy indicator' },
    { value: '39', text: 'Private room medically necessary' },
    { value: '50', text: 'Product replacement for known recall of a product' },
    { value: '49', text: 'Product replacement within product lifecycle' },
    {
        value: '77',
        text:
            'Provider accepts or is obligated/required due to a contractual arrangement or law to accept payment by a primary payer as payment in full'
    },
    { value: '66', text: 'Provider does not wish cost outlier payment' },
    {
        value: '48',
        text: 'Psychiatric residential treatment centers (RTCs) for children and adolescents'
    },
    { value: 'H3', text: 'Re-occurrence of GI Bleed MA Category' },
    { value: 'H5', text: 'Re-occurrence of Pericarditis MC Category' },
    { value: 'H4', text: 'Re-occurrence of Pneumonia MB Category' },
    { value: '91', text: 'Report when service is provided as an Emergency Use Authorization (EAU)' },
    { value: '90', text: 'Report when service is provided as part of an expanded Access Approval' },
    { value: 'R4', text: 'Request for Reopening Reason Code - Computer Errors' },
    { value: 'R7', text: 'Request for Reopening Reason Code - Corrections other than Clerical Errors' },
    { value: 'R9', text: 'Request for Reopening Reason Code - Faulty Evidence' },
    { value: 'R2', text: 'Request for Reopening Reason Code - Inaccurate Data Entry' },
    { value: 'R5', text: 'Request for Reopening Reason Code - Incorrectly Identified Duplicate Claim' },
    { value: 'R1', text: 'Request for Reopening Reason Code - Mathematical or Computational Mistakes' },
    { value: 'R3', text: 'Request for Reopening Reason Code - Misapplication of a Fee Schedule' },
    { value: 'R8', text: 'Request for Reopening Reason Code - New and Material Evidence' },
    {
        value: 'R6',
        text:
            'Request for Reopening Reason Code - Other Clerical Errors or Minor Errors and Omissions not Specified in R1 - R5'
    },
    { value: '47', text: 'Reserved for champus' },
    { value: '35', text: 'Reserved for national assignment' },
    { value: 'MH', text: 'Reserved for Payer Assignment' },
    { value: 'MI', text: 'Reserved for Payer Assignment' },
    { value: 'MJ', text: 'Reserved for Payer Assignment' },
    { value: 'MK', text: 'Reserved for Payer Assignment' },
    { value: 'ML', text: 'Reserved for Payer Assignment' },
    { value: 'MM', text: 'Reserved for Payer Assignment' },
    { value: 'MN', text: 'Reserved for Payer Assignment' },
    { value: 'MO', text: 'Reserved for Payer Assignment' },
    { value: 'MP', text: 'Reserved for Payer Assignment' },
    { value: 'MQ', text: 'Reserved for Payer Assignment' },
    { value: 'MR', text: 'Reserved for Payer Assignment' },
    { value: 'MS', text: 'Reserved for Payer Assignment' },
    { value: 'MT', text: 'Reserved for Payer Assignment' },
    { value: 'MU', text: 'Reserved for Payer Assignment' },
    { value: 'MV', text: 'Reserved for Payer Assignment' },
    { value: 'MW', text: 'Reserved for Payer Assignment' },
    { value: 'M1', text: 'Roster Billed Influenza Virus Vaccine or Pneumococcal Pneumonia Vaccine' },
    { value: '40', text: 'Same day transfer' },
    { value: 'A9', text: 'Second opinion surgery' },
    { value: 'D3', text: 'Second or subsequent interim PPS bill' },
    { value: '70', text: 'Self administered anemia management drug' },
    { value: '72', text: 'Self care in unit' },
    { value: '73', text: 'Self care training' },
    { value: '38', text: 'Semiprivate room not available' },
    { value: 'M3', text: 'SNF 3 days stay bypass for NG/Pioneer ACO Waiver' },
    { value: '55', text: 'SNF bed not available' },
    { value: '57', text: 'SNF readmission' },
    { value: '16', text: 'SNF Transition exception - Payer Use Only Code' },
    { value: 'A3', text: 'Special federal funding' },
    { value: 'AL', text: 'Specialized treatment/bed unavailable' },
    { value: 'AI', text: 'Sterilization' },
    { value: 'MY', text: 'Surgery on Wrong Body Part - Payer Use Only Code' },
    { value: 'MZ', text: 'Surgery on Wrong Patient - Payer Use Only Code' },
    { value: '58', text: 'Terminated medicare advantage enrollee' },
    { value: '7', text: 'Treatment of nonterminal condition for hospice patient' },
    { value: '88', text: 'True emergency' },
    { value: 'Y0', text: 'Under 65, does not have Medicare coverage' },
    { value: 'UU', text: 'Unified Stay - Payer Use Only Code' },
    { value: 'W0', text: 'United mine workers of America (UMWA) demonstrator indicator' },
    {
        value: '26',
        text: 'VA-eligible patient chooses to receive services in medicare-certified facility'
    },
    { value: 'A6', text: 'Vaccines/medicare 100% payment' },
    { value: '37', text: 'Ward accommodation at patients request' }
];

export const ROIOptions = [
    {
        value: 'I',
        text:
            'Informed Consent to Release Medical Information for Conditions or Diagnoses Regulated by Federal Statutes'
    },
    {
        value: 'Y',
        text: 'Yes, Provider has a Signed Statement Permitting Release of Medical Billing Data Related to a Claim'
    }
];

export const AssignBenefitsOptions = [
    { value: 'N ', text: 'No' },
    { value: 'W ', text: 'Not Applicable' },
    { value: 'Y ', text: 'Yes' }
];

export const MediaCodeOptions = [
    { value: 'E', text: 'Electronic' },
    { value: 'P', text: 'G4 Print Service' },
    { value: 'L', text: 'Print Locally' }
];

export const ValueCodeOptions = [
    { value: '74', text: '74 - (Payer Only Code Not currently used by Medicare)' },
    { value: 'Q7', text: 'Q7 - (Payer Only Code) Not currently used by Medicare' },
    { value: 'Q9', text: 'Q9 - (Payer Only Code) Not currently used by Medicare' },
    { value: '45', text: '45 - Accident hour' },
    { value: 'Q0', text: 'Q0 - Accountable Care Organization Reduction (Payer Only Code)' },
    {
        value: '44',
        text:
            '44 - Amount provider agreed to accept from the primary insurer when this amount is less than total charges, but higher than the payment received'
    },
    { value: '47', text: '47 - Any liability insurance' },
    { value: '58', text: '58 - Arterial blood gas (PO2/PA2)' },
    { value: '41', text: '41 - Black lung' },
    { value: '38', text: '38 - Blood deductible units' },
    { value: 'P3', text: 'P3 - BLOOD PRESSURE - DIASTOLIC (EFFECTIVE 7/1/19)' },
    { value: 'P1', text: 'P1 - BLOOD PRESSURE - SYSTOLIC (EFFECTIVE 7/1/19)' },
    { value: 'P2', text: 'P2 - BLOOD PRESSURE - SYSTOLIC (EFFECTIVE 7/1/19)' },
    { value: '53', text: '53 - Cardiac rehabilitation visits' },
    { value: '21', text: '21 - Catastrophic' },
    { value: '91', text: '91 - Charges for kidney acquisition ( effective 10/01/21)' },
    { value: 'D4', text: 'D4 - Clinical trial number assigned by the NLM/NIH' },
    { value: 'A7', text: 'A7 - Co-payment payer a reportable on paper claims' },
    { value: 'B7', text: 'B7 - Co-payment payer B, reportable on paper claims' },
    { value: 'C7', text: 'C7 - Co-payment payer C, reportable only on paper claims' },
    { value: '11', text: '11 - Coinsurance amount for second calendar year' },
    { value: '82', text: '82 - Coinsurance days (reportable only on paper ub04)' },
    { value: 'A2', text: 'A2 - Coinsurance payer A reportable only on paper claims' },
    { value: 'B2', text: 'B2 - Coinsurance payer B, reportable only on paper claims' },
    { value: 'C2', text: 'C2 - Coinsurance payer C, reportable only on paper claims' },
    { value: 'Y4', text: 'Y4 - Conventional provider payment for non-demonstration claims' },
    { value: '85', text: '85 - County Where Service is Rendered' },
    { value: '80', text: '80 - Covered days (reportable only on paper ub04)' },
    { value: 'A4', text: 'A4 - Covered self administrable drugs emergency' },
    {
        value: 'A5',
        text: 'A5 - Covered self administrable drugs not self administrable in form and situation furnished to patient'
    },
    { value: 'A6', text: 'A6 - Covered self administrable drugs-diagnostic study and other' },
    { value: 'FD', text: 'FD - Credit received from the manufacturer for a replaced medical device' },
    { value: 'A1', text: 'A1 - Deductible payer A reportable only on paper ub04' },
    { value: 'B1', text: 'B1 - Deductible payer B, reportable only on paper claims' },
    { value: 'C1', text: 'C1 - Deductible payer C, reportable only on paper claims' },
    { value: 'QD', text: 'QD - Device Credit (Payer Only Code)' },
    { value: 'QU', text: 'QU - Device credit with device offset (Payer Only Code)' },
    { value: '43', text: '43 - Disabled beneficiary under age 65 with LGHP' },
    { value: 'Q5', text: 'Q5 - EHR (Payer Only Code)' },
    { value: '55', text: '55 - Eligibility threshold for charity care' },
    { value: '68', text: '68 - EPO - drug' },
    { value: '13', text: '13 - ESRD beneficiary in a medicare coordination period with an EGHP' },
    { value: '79', text: '79 - ESRD Outlier Allowable Amount (Payer Only Code)' },
    { value: 'D3', text: 'D3 - Estimated responsibility patient' },
    { value: 'A3', text: 'A3 - Estimated responsibility payer A' },
    { value: 'B3', text: 'B3 - Estimated responsibility payer B' },
    { value: 'C3', text: 'C3 - Estimated responsibility payer C' },
    { value: 'G8', text: 'G8 - Facility where inpatient hospice service is delivered' },
    { value: 'QN', text: 'QN - First APC device offset (Payer Only Code)' },
    { value: 'QR', text: 'QR - First APC pass-through drug or biological offset (Payer Only Code)' },
    { value: '72', text: '72 - Flat Rate Surgery (Payer Only Code)' },
    { value: '71', text: '71 - Funding of ESRD Networks (Payer Only Code)' },
    { value: '49', text: '49 - Hematocrit reading' },
    { value: '48', text: '48 - Hemoglobin reading' },
    { value: '64', text: '64 - HH reimbursement - Part A (Payer Only Code)' },
    { value: '65', text: '65 - HH reimbursement - Part B (Payer Only Code)' },
    { value: '62', text: '62 - HH visits - Part A (Payer Only Code)' },
    { value: '63', text: '63 - HH visits - Part B (Payer Only Code)' },
    { value: '60', text: '60 - HHA branch MSA' },
    { value: '57', text: '57 - Home health aide - home visits hours (HHA only)' },
    { value: 'Q2', text: 'Q2 - Hospice claim paid from Part B Trust Fund (Payer Only Code)' },
    { value: '02', text: '02 - Hospital has no semiprivate rooms' },
    { value: '04', text: '04 - Inpatient professional component charges that are combined billed' },
    { value: '70', text: '70 - Interest (Payer Only Code)' },
    { value: 'D5', text: 'D5 - Last Kt/V Reading' },
    { value: '10', text: '10 - Lifetime reserve amount in the second calendar year' },
    { value: '83', text: '83 - Lifetime reserve days (reportable only on ub04)' },
    { value: '24', text: '24 - Medicaid rate code' },
    { value: '66', text: '66 - Medicaid spend down amount' },
    { value: '06', text: '06 - Medicare blood deductible' },
    { value: '09', text: '09 - Medicare coinsurance amount in the first calendar year' },
    { value: '08', text: '08 - Medicare lifetime reserve amount in the first calendar year' },
    { value: '77', text: '77 - Medicare New Technology Add-On (Payer Only Code)' },
    { value: '01', text: '01 - Most common semiprivate room rate' },
    { value: '32', text: '32 - Multiple patient ambulance transport' },
    { value: '40', text: '40 - New coverage not implemented by HMO for inpatients only' },
    { value: '54', text: '54 - Newborn birth weight in grams' },
    { value: '14', text: '14 - No-fault, including auto/other' },
    { value: '81', text: '81 - Non covered days (reportable only on paper ub04)' },
    { value: '46', text: '46 - Number of grace days' },
    { value: '51', text: '51 - Occupational therapy visits' },
    { value: '29', text: '29 - Offset to the patient payment amount - chiropractic services' },
    { value: '28', text: '28 - Offset to the patient payment amount - dental services' },
    { value: '35', text: '35 - Offset to the patient payment amount - health insurance premiums' },
    { value: '26', text: '26 - Offset to the patient payment amount - hearing and ear services' },
    { value: '34', text: '34 - Offset to the patient payment amount - other medical services' },
    { value: '33', text: '33 - Offset to the patient payment amount - podiatric services' },
    { value: '25', text: '25 - Offset to the patient payment amount - prescription drugs' },
    { value: '27', text: '27 - Offset to the patient payment amount - vision and eye services' },
    { value: '18', text: '18 - Operating Disproportionate Share Amount (Payer Only Code)' },
    {
        value: '19',
        text:
            '19 - Operating Indirect Medical Education Amount and ESRD: Low Volume Adjustment Amount (Payer Only Code)'
    },
    { value: '17', text: '17 - Operating Outlier Amount (Payer use only)' },
    { value: 'AB', text: 'AB - Other assessments or allowances ie: medical education, payer A' },
    { value: 'CB', text: 'CB - Other assessments or allowances, medical education payer C' },
    { value: 'BB', text: 'BB - Other assessments or allowances, medical education, payer B' },
    { value: '59', text: '59 - Oxygen saturation (O2 Sat/oximetry)' },
    { value: 'Y1', text: 'Y1 - Part A demonstration payment' },
    { value: 'Y3', text: 'Y3 - Part B coinsurance' },
    { value: 'Y5', text: 'Y5 - Part B Deductible' },
    { value: 'Y2', text: 'Y2 - Part B demonstration payment' },
    { value: 'A9', text: 'A9 - Patient height' },
    { value: '31', text: '31 - Patient liability amount' },
    { value: 'FC', text: 'FC - Patient paid amount' },
    { value: 'A8', text: 'A8 - Patient weight' },
    { value: 'QA', text: 'QA - Payer Only Code' },
    { value: 'QB', text: 'QB - Payer Only Code' },
    { value: 'QC', text: 'QC - Payer Only Code' },
    { value: 'QE', text: 'QE - Payer Only Code' },
    { value: 'QF', text: 'QF - Payer Only Code' },
    { value: 'QG', text: 'QG - Payer Only Code' },
    { value: 'QH', text: 'QH - Payer Only Code' },
    { value: 'QI', text: 'QI - Payer Only Code' },
    { value: 'QJ', text: 'QJ - Payer Only Code' },
    { value: 'QK', text: 'QK - Payer Only Code' },
    { value: 'QL', text: 'QL - Payer Only Code' },
    { value: 'QM', text: 'QM - Payer Only Code' },
    { value: 'QW', text: 'QW - Payer Only Code' },
    { value: 'QX', text: 'QX - Payer Only Code' },
    { value: 'QY', text: 'QY - Payer Only Code' },
    { value: 'QZ', text: 'QZ - Payer Only Code' },
    { value: '78', text: '78 - Payer only value code (Payer Only Code)' },
    { value: '67', text: '67 - Peritoneal dialysis' },
    { value: '50', text: '50 - Physical therapy visits' },
    { value: 'Q1', text: 'Q1 - Pioneer payment reduction (Payer Only Code)' },
    { value: '61', text: '61 - Place of Residence where service is furnished (HHA and Hospice)' },
    { value: 'QP', text: 'QP - Placeholder reserved for future use (Payer Only Code)' },
    { value: 'Q6', text: 'Q6 - PQRS (Payer Only Code)' },
    { value: '30', text: '30 - Preadmission testing' },
    { value: 'Q3', text: 'Q3 - Prior authorization 25% Penalty (Payer Only Code)' },
    { value: '75', text: '75 - Prior covered days for an interrupted stay (Payer Only Code)' },
    {
        value: '05',
        text: '05 - Professional component included in charges and also billed separately to carrier'
    },
    { value: '76', text: "76 - Provider's Interim Rate (Payer Only Code)" },
    { value: '16', text: '16 - Public health service (PHS) or other federal agency' },
    { value: '23', text: '23 - Recurring monthly income' },
    {
        value: 'AA',
        text: 'AA - Regulatory surcharges, assessments or health care related taxes Payer A'
    },
    {
        value: 'BA',
        text: 'BA - Regulatory surcharges, assessments, allowances or health care related taxes pay B'
    },
    {
        value: 'CA',
        text: 'CA - Regulatory surcharges, assessments, allowances or health care related taxes payer C'
    },
    { value: 'Q4', text: 'Q4 - Reserved for Future Medicare use (Payer Only Code)' },
    { value: 'QO', text: 'QO - Second APC device offset (Payer Only Code)' },
    { value: 'QS', text: 'QS - Second APC pass-through drug or biological offset (Payer Only Code)' },
    { value: '73', text: '73 - Sequestration adjustment amount (Payer Only Code)' },
    {
        value: '84',
        text: '84 - Shorter Duration Hemodialysis (Implementation deferred. Effective date to be determined)'
    },
    { value: '56', text: '56 - Skilled nurse-home visit hours (HHA only)' },
    { value: '86', text: '86 - Special Zip Code Reporting' },
    { value: 'A0', text: 'A0 - Special zip reporting' },
    { value: '52', text: '52 - Speech therapy visits' },
    { value: '69', text: '69 - State charity care percent' },
    { value: '22', text: '22 - Surplus' },
    {
        value: 'QQ',
        text:
            'QQ - Terminated procedure with pass-through device OR condition for device credit present (Payer Only Code)'
    },
    { value: 'QT', text: 'QT - Third APC pass-through drug or biological offset (Payer Only Code)' },
    {
        value: 'D6',
        text: 'D6 - TOTAL NUMBER OF MINUTES OF DIALYSIS PROVIDED DURING THE BILLING PERIOD (EFFECTIVE 1/1/21)'
    },
    { value: '20', text: '20 - Total payment sent provider for capital under PPS (Payer Only Code)' },
    {
        value: 'Q8',
        text: 'Q8 - Transistional Drug Add-on Payment Adjustment (TDAPA) (Payer Only Code)'
    },
    { value: '37', text: '37 - Units of blood furnished' },
    { value: '39', text: '39 - Units of blood replaced' },
    { value: 'QV', text: 'QV - Value-based Purchasing Adjustment Amount (Payer Only Code)' },
    { value: '15', text: '15 - Workers compensation' },
    { value: '12', text: '12 - Working aged beneficiary/spouse with EGHP' },
    { value: '42', text: '42 - Veterans affairs' }
];

export const RevenueCodeOptions = [
    { value: '0001', text: 'Total Charges' },
];

export const ClaimIndCodeOptions = [
    { value: "AM", text: "AM - Automobile Medical" },
    { value: "BL", text: "BL - Blue Cross/Blue Shield" },
    { value: "10", text: "10 - Central Certification" },
    { value: "CH", text: "CH - Champus" },
    { value: "CI", text: "CI - Commercial Insurance Co." },
    { value: "DS", text: "DS - Disability" },
    { value: "14", text: "14 - Exclusive Provider Organization (EPO)", },
    { value: "HM", text: "HM - Health Maintenance Organization" },
    { value: "16", text: "16 - Health Maintenance Organization (HMO) Medicare Risk" },
    { value: "15", text: "15 - Indemnity Insurance" },
    { value: "LI", text: "LI - Liability" },
    { value: "LM", text: "LM - Liability Medical" },
    { value: "MC", text: "MC - Medicaid" },
    { value: "MA", text: "MA - Medicare Part A" },
    { value: "MB", text: "MB - Medicare Part B" },
    { value: "ZZ", text: "ZZ - Mutually Defined" },
    { value: "OF", text: "OF - Other Federal Program" },
    { value: "11", text: "11 - Other Non-Federal Programs" },
    { value: "13", text: "13 - Point of Service (POS)", },
    { value: "12", text: "12 - Preferred Provider Organization (PPO)" },
    { value: "09", text: "09 - Self-pay" },
    { value: "TV", text: "TV - Title V" },
    { value: "WC", text: "WC - Workers' Compensation Health Claim" }
]